import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {COLORS} from '../values/colors'

const UserIcon = ({ initials, user, size }) => {
    if (user) {
        initials = user.first_name.split('')[0] + user.last_name.split('')[0];
    }
    return (
        <IconContainer size={size}>
            <IconText>{initials && initials.toUpperCase()}</IconText>
        </IconContainer>
    );
};

UserIcon.propTypes = {
    initials: PropTypes.string,
    user: PropTypes.object,
    size: PropTypes.number,
};

const IconContainer = styled.div`
    background-color: #bbb;
    width: ${props => props.size}px;
    min-width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-size: calc(19.5px / 37 * ${props => props.size});
    &:hover {
        background-color: #999;
    }
`;

const IconText = styled.b`
    color: white;
    margin: 0;
    padding: 0;
    user-select: none;
`;

export default UserIcon;