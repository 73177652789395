import React, { useEffect, useState, useRef} from 'react';
import { styled } from "styled-components";
import Button from 'react-bootstrap/Button';
import { COLORS } from "../values/colors.js";
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import UserIcon from "./UserIcon.js";
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import { searchUsers } from '../services/api/search';
import { listDMs, getDM, createDM } from '../services/api/dms';
import { getGroup, getGroupMembers, getSharedAttachment, getSharedAttachments, sendMessage, getGroupMessages } from "../services/api/groups.js";
import { createShare, getAttachments } from "../services/api/attachments.js";
import CloseButton from 'react-bootstrap/CloseButton';
import CasePHIModal from "./CasePHIModal.js";


const ChooseUserItem = ({ user, handleAddUser }) => {
    return (
        <UserListItem onClick={() => handleAddUser(user)}>
            <UserIcon user={user} size={25}/>
            <UserInfoContainer>
                <span><b>{user.first_name + ' ' + user.last_name}</b></span>
                <span className='company'>{user.company}</span>
            </UserInfoContainer>
        </UserListItem>
    );
}

const InviteUserItem = ({ searchTerm }) => {
    return (
        <UserListItem onClick={() => {}}>
            <UserInfoContainer>
                <span className='company'>Can't find who you're looking for?</span>
                <span>Invite <b>{searchTerm}</b> to join you on Orchid!</span>
            </UserInfoContainer>
        </UserListItem>
    );
}


const UserListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 3px 10px;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
    gap: 10px;

    &:hover {
        background-color: #f5f5f5;
    }
    `;

const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    .company {
        font-size: 12px;
        color: #666;
    }
    `;

const ChooseUserList = ({ users, handleAddUser, searchTerm }) => {
    return (
        <ListContainer>
        {users.map((user, index) => {
            return (
                <ChooseUserItem key={index} user={user} handleAddUser={handleAddUser} />
            )
        })}
        {searchTerm?.length>0 && users?.length === 0 && <span>No results found, try another search! </span>}
        {/* <InviteUserItem searchTerm={searchTerm} /> */}
        </ListContainer>
    );
}

const ListContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: min-content(300px, 100%);
    overflow: auto;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    justify-self: center;

    @media (max-width: 768px) {
        width: calc(100% - 20px);
    }
    `;

const ChooseUsers = ({ userList, setUserList }) => {
    const [ query, setQuery ] = useState('');
    const [ searchResults, setSearchResults ] = useState([]);
    const [ isSearching, setIsSearching ] = useState(false);
    const userNameContainerRef = useRef(null);
    const userSelf = useSelector(state => state.user.value);

    useEffect(() => {
        if (userNameContainerRef.current) {
            userNameContainerRef.current.scrollLeft = 1000;
        }
    }
    , [userList]);

    const handleSearch = (e) => {
        setQuery(e.target.value);
        if (e.target.value.length > 2) {
            let timer = setDelayedIsSearching();
            searchUsers(e.target.value).then(items => {
                setSearchResults(items.filter(item => item.id !== userSelf.id));
                clearTimeout(timer);
                setIsSearching(() => false);
            });
        }
        else {
            setSearchResults([]);
        }
    }

    const handleAddUser = (user) => {
        if (!userList.find(item => (item.id === user.id))) {
            setUserList([...userList, user]);
        }
        setSearchResults([]);
        setQuery('');
    }

    const handleRemoveUser = (user) => {
        setUserList(userList.filter(item => item.id !== user.id));
    }

    function setDelayedIsSearching (delay=300) {
        //use a timer to show the spinner after a short delay
        //to avoid flickering
        return setTimeout(() => {
            setIsSearching(true);
        }
        , delay);
    }

    const [hint, setHint] = useState('Enter a name, email, or organization...');
    useEffect(() => {
        if (userList.length > 0) {
            setHint('');
        }
        else {
            setHint('Enter a name, email, or organization...');
        }
    }
    , [userList]);

    return (
        <>
            <UserSearchInput>
                <span className='status'> {isSearching ? <Spinner size='sm' variant='secondary'/> : 'To:'} </span>
                <UserNamesArea ref={userNameContainerRef}>
                {userList && userList.length>0 && userList.map((user, index) => {
                    return (
                        <UserNameContainer key={index}>
                            <span>{user.first_name + ' ' + user.last_name}</span>
                            <XButton onClick={() => handleRemoveUser(user)}/>
                        </UserNameContainer>
                    )
                })}
                <input type="text" value={query} onChange={handleSearch} placeholder={hint}/>
                </UserNamesArea>
            </UserSearchInput>

            <div>
                {searchResults && query.length>2 && userList.length <4 &&<ChooseUserList users={searchResults} handleAddUser={handleAddUser} searchTerm={query}/>}
                {searchResults && query.length>2 && userList.length >=4 && <ListContainer>Sharing with more than four users? <br/>Check out Orchid Groups!</ListContainer>}
            </div>
        </>
    );
}

const UserSearchInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 3px;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;

    .status {
        width: 30px;
        text-align: center;
    }

    input {
        border: 0px;
        flex: 1 0 150px;
        &:focus {
            outline: none;
        }
    }

    @media (max-width: 768px) {
        padding-left: 40px;
    }
`;

const UserNamesArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: default;
    width: 100%;
    `;

const UserNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 3px 10px;
    cursor: pointer;
    background-color: #0d6efd;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    margin: 2px 5px 2px 0px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    `;

const XButton = styled.div`
    position: relative;
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-left: 5px;

    &:before, &:after {
        content: '';
        position: absolute;
        width: 15%;
        height: 100%;
        background-color: white;
        transform-origin: center;
    }
    
    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    `;

const AttachmentSelector = ({group_id, selectorIsOpen, setSelectorIsOpen, attachmentsToSend, setAttachmentsToSend, showPHIModal, setShowPHIModal,
    currentSelection, setCurrentSelection}) => {
    
        const [attachments, setAttachments] = useState([]);
        const user = useSelector(state => state.user.value);
    
        useEffect(() => {
    
            setAttachmentsToSend([]);
    
            getAttachments().then(items => {
                items = items.filter((item) => item.case.owner.id === user.id);
                items = items.filter((item) => item.status > 2);
                setAttachments(items);
            })
    
        }, [showPHIModal])
    
        function determineShareState(attachment) {
            //attestations not complete
            if (attachment.contains_phi === null || attachment.with_patient_consent === null)
                return [true, 'This video requires PHI attestations before it can be shared; click to provide.'];
            //attestations complete
            if ( attachment.contains_phi === true && attachment.with_patient_consent === false)
                return [false, 'This video cannot be shared in this message based on the PHI attestations previously provided. If you believe this is in error, revisit the sharing settings for this video.'];
            return [true, 'This video is ready to be shared!'];
        }
    
        return (
            <>
            {selectorIsOpen &&
            <AttachmentSelectorContainer>
            <div className='instructions'>
                <span>Choose videos to share:</span>
                <CloseButton onClick={() => setSelectorIsOpen(false)}/>
            </div>
            <AttachmentsContainer>
                    {attachments.map((attachment, idx) => {
                        let isSelected = attachmentsToSend.some(item => item.id === attachment.id);
                        let shareState = determineShareState(attachment);
                        return (
                            <AttachmentContainer key={idx}>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 100, hide: 100 }}
                                    overlay={<Tooltip>{shareState[1]}</Tooltip>}
                                >
                                <ThumbnailContainer key={idx}>
                                    <Thumbnail 
                                        isSelected={isSelected}
                                        shareState={shareState[0]}
                                        src={attachment.thumbnail.download_link} 
                                        width='100' 
                                        onClick={() => {
                                            setCurrentSelection(attachment)
                                            if (shareState[0] === false)
                                                return;
                                            if (isSelected) {
                                                setAttachmentsToSend(attachmentsToSend.filter((item) => item.id !== attachment.id));
                                                return;
                                            }
                                            if (attachment.contains_phi === null || attachment.with_patient_consent === null) {
                                                setShowPHIModal(true);
                                                return;
                                            }
                                            setAttachmentsToSend([...attachmentsToSend, attachment]);
                                        }}
                                    />
                                </ThumbnailContainer>
                                </OverlayTrigger>
                                <span className='description'>{attachment.case.case_name}</span>
                            </AttachmentContainer>
                        )
                    }
                    )}
            </AttachmentsContainer>
            </AttachmentSelectorContainer>
            }
            </>
        )
    }
    
    const AttachmentSelectorContainer = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 5px;
        margin-top: 0px;
        padding-top: 0px;
        overflow: hidden;
    
        .instructions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0px 0px 5px 10px;
            font-weight: 600;
        }
    `;
    
    const AttachmentsContainer = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
        background-color: white;
        width: 100%;
        overflow: auto;
        gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
    
        mask-image: linear-gradient(to right, transparent 0%, white 10px, white calc(100% - 10px), transparent 100%);
        -webkit-mask-image: linear-gradient(to right, transparent 0%, white 10px, white calc(100% - 10px), transparent 100%); /* For Safari */
    
        `;
    
    const AttachmentContainer = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
    
        .description {
            font-size: 14px;
            font-weight: 600;
            padding: 5px 0px;
        }
        `;


const NewChatArea = ({groups, setGroups, setActiveGroup}) => {

    const [members, setMembers] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [displayedMessages, setDisplayedMessages] = useState([]);
    const [messageText, setMessageText] = useState('');
    const user = useSelector(state => state.user.value);
    const [attachmentsToSend, setAttachmentsToSend] = useState([]);
    const [selectorIsOpen, setSelectorIsOpen] = useState(false);
    const [showPHIModal, setShowPHIModal] = useState(false);
    const [currentSelection, setCurrentSelection] = useState(null);

    function createLocalMessage(text) {
        return {
            id: null,
            text: text,
            user_id: user.id,
            timestamp: new Date().toISOString().split("Z")[0]
        }
    }
    
    function createNewChat() {
        if (members.length===0) return; 
        createDM(members.map(member => member.id))
        .then(dmGroup => {
            if (messageText.trim() !== '') {
                setDisplayedMessages((displayedMessages) => [...displayedMessages, createLocalMessage(messageText)])
                sendMessage(dmGroup.id, {text: messageText});
                setMessageText('');
                mixpanel.track('Sent Message', {
                    'Source': 'Messages Page',
                    'Group ID': dmGroup.id,
                    'Message Length': messageText.length
                })
            }
            if (attachmentsToSend.length > 0) {
                Promise.all(attachmentsToSend.map(attachment => {
                    console.log('sharing attachment ' + attachment.id + ' with group ' + dmGroup.id)
                    setDisplayedMessages((displayedMessages) => [...displayedMessages, createLocalMessage('ATTACHMENT_SHARE:ATTACHMENT_ID:' + attachment.id)])
                    return createShare(attachment.id, { "group_id" : dmGroup.id });
                }))
                .then(() => {
                    attachmentsToSend.map(attachment => {
                        mixpanel.track('Shared Video', 
                        {
                            'Source': 'Messages Page', 
                            'Group ID': dmGroup.id, 
                            'Attachment ID': attachment.id
                        }
                    )})
                    setAttachmentsToSend([]);
                })
            }

            mixpanel.track('Created New DM',
                {'User IDs': members.map(member => member.id)}
            );
            //if groups does not contain dmGroup.id, add it
            if (!groups.find(group => group.id === dmGroup.id)) {
                    setGroups(() => [dmGroup, ...groups ]);    
            }
            setActiveGroup(dmGroup.id);
        });
    }

    return (
        <ChatContainer>
            <ChooseUsers userList={members} setUserList={setMembers} />
            <MessageDisplay>
            </MessageDisplay>
            <InputArea>
            <AttachmentSelector
                group_id={null}
                selectorIsOpen={selectorIsOpen}
                setSelectorIsOpen={setSelectorIsOpen}
                attachmentsToSend={attachmentsToSend}
                setAttachmentsToSend={setAttachmentsToSend}
                showPHIModal={showPHIModal}
                setShowPHIModal={setShowPHIModal}
                currentSelection={currentSelection}
                setCurrentSelection={setCurrentSelection}
            />
            <MessageBox 
                type="text" 
                value={messageText} 
                placeholder='Enter a message...'
                onChange={(e) => setMessageText(e.target.value)} 
                onKeyUp={(e) => {
                    e.preventDefault();
                    if (e.key === 'Enter'&& !e.shiftKey) {
                        createNewChat();
                    }
                }}
                />
            <MessageControls>
                <AttachmentList>
                    {attachmentsToSend.length > 0 &&
                            attachmentsToSend.map((attachment, idx) => {
                                return (
                                    <AttachmentItem key={idx}>{`\u23F5 ` + attachment.case.case_name}</AttachmentItem>
                                )
                            })
                        }
                </AttachmentList>
            <ButtonContainer>
                <Button 
                    size="sm"
                    onClick={() => {setSelectorIsOpen(!selectorIsOpen);}}
                    >
                    +
                </Button>
                <Button 
                    size="sm"
                    onClick={createNewChat}
                    >
                    Send
                </Button>
            </ButtonContainer>
            </MessageControls>
        </InputArea>
        <CasePHIModal
            show={showPHIModal}
            onHide={() => setShowPHIModal(false)}
            onSave={() => {}}
            user={user}
            attachment={currentSelection}
        />
    </ChatContainer>
    )
}

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    /* min-width: 500px; */
`;

const MessageDisplay = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 0px 10px;
    align-items: flex-start;
    justify-content: flex-start;
`;


const InputArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    /* border-top: 2px solid ${COLORS.orchid_teal}; */

    textarea {
        border: 0px solid white;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.04);
        padding: 5px 10px;
        &:focus {
            outline: none;
        }
        margin-bottom: 5px;
    }
`;

const MessageBox = styled.textarea`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3px 5px;
    height: 80px;
    resize: none;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    button {
        font-weight: 600;
    }
`;

const AttachmentList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    height: auto;
    width: 100%;
    padding: 5px 0px;
`;  

const MessageControls = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    height: auto;
    width: 100%;
    padding: 5px 0px;
`;

const AttachmentItem = styled.div`
    display: flex;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 7px;
    border-radius: 15px;
    background-color: ${COLORS.orchid_teal};
`;

const ThumbnailContainer = styled.div`
    position: relative;
    display: inline-block;
    transition: opacity 100ms ease-in-out;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
        div {opacity: 0.7;}
    }
    `;

const Thumbnail = styled.img`
    width: 180px;
    height: auto;
    border-radius: 5px;

    ${props => props.isSelected && `
        transform: scale(.96);
        filter: brightness(80%);
    `}
    ${props => props.shareState === false && `
        opacity: .2;
    `}
`;

export default NewChatArea;