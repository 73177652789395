import {get, post, patch, del, paramsToString} from './common.js';


// request new export
export function requestNewExport(attachment_id) {
    return post('/exports/', {'attachment_id': attachment_id});
}

// get exports
export function getExports(params) {
    return get(`/exports/`, 'api', params);
}

// get export by id
export function getExport(id) {
    return get(`/exports/${id}/`);
}
