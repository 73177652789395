import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { persistor, store } from './services/redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as Sentry from "@sentry/react";
import { environment, gtag_id } from './sources.js';

Sentry.init({
  dsn: "https://a37a3f15819988b244ae806ac43125a2@o4506482510135296.ingest.sentry.io/4506482734989312",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/orchidsurgical\.com/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: environment,
});

// add google analytics script
const script = document.createElement("script");
script.src = `https://www.googletagmanager.com/gtag/js?id=${gtag_id}`;
script.async = true;
document.body.appendChild(script);

// add google analytics script
const script2 = document.createElement("script");
script2.innerHTML = `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${gtag_id}');`;
document.body.appendChild(script2);
document.body.style.overscrollBehaviorX = 'contain';

// add freshworks crm script
// const script3 = document.createElement("script");
// script3.innerHTML = `https://fw-cdn.com/2386681/2988252.js`;
// script3.chat = 'true';
// script.async = true;

// document.body.appendChild(script3);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
