import { styled } from "styled-components";
import React, { useState, useEffect, useRef } from 'react';
import { url_api } from '../sources.js';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getMe } from "../services/api/me.js";
import Button from '../store/components/Button.js';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import mixpanel from 'mixpanel-browser';
import { gtag_id, gtag_config } from "../sources.js";
import { setUser } from "../services/redux/userSlice.js";
import { useDispatch } from 'react-redux';
import Header from "../store/components/Header.js";
import { HeaderBackground } from "../store/pages/LandingPage.js";
import { GlassContainer } from "../store/components/GlassContainer.js";
import MfaInput from "./MfaInput.js";

async function loginUser(credentials) {
    return fetch(url_api + '/v1/auth/login/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
}

const Login = () => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [inputIsDisabled, setInputIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [requiresMfa, setRequiresMfa] = useState(false);
    const [mfaCode, setMfaCode] = useState(null);
    const mounted = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const dispatch = useDispatch();



    function setupSession(user) {

        //set session user
        dispatch(setUser(user))
        localStorage.setItem('isHijacked', false);

        //set tracker users
        window.gtag('config', gtag_id,  
        {
            'user_id': user.id,
            ...gtag_config
        });
        mixpanel.identify(user.id);
        mixpanel.track('Logged In', {'Source': 'Login Page'})
    
        //redirect
        setTimeout(() => {
            //navigate to the previous page
            if (state && state.from) {
                navigate({pathname: state.from, search: state.params});
            }
            else {
                navigate('/home/');
            }
        }
        , 1000);
    }

    useEffect(() => {
        document.title = "Orchid Cloud | Sign In";
      }, []);

    // useEffect hook to check if the user is already logged in
    useEffect(() => {
        if (mounted.current) {
            getMe().then(user => {
                if (user) {
                    setInputIsDisabled(true)
                    setSuccessMessage("You are already logged in!")
                    setEmail(user.email)
                    setPassword('0000000000000')
                    setupSession(user)
                }
            })
        }
        return () => mounted.current = false;
    }, [])

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);
        const response = await loginUser({
          email: email,
          password: password,
          otp_code: mfaCode
        });
        if (response.status === 200) {
            const user = await response.json();
            setSuccessMessage("You are now logged in!")
            setupSession(user)
        }
        else if (response.status === 406) {
            // mfa required
            setRequiresMfa(true);
            setIsLoading(false);
        }
        else {
            setMfaCode(null);
            setIsLoading(false);
            setSuccessMessage("Login failed. Please try again.")
        }
    }

    return ( 
        <PageContainer>
             <HeaderBackground>
                <img src="/images/store/Landing/landing_bkg.svg" alt="background shapes" />
            </HeaderBackground>
            <Header />
            {/* <div style={{display: 'flex', height: "max(50vh, 300px)", alignSelf: "center"}}> */}
            <div style={{display: 'flex', height: "100%", alignItems: "center"}}>
            <div style={{display: 'flex', minHeight: "50vh", width: "60vw"}}>
            <GlassContainer>
                <Wrap>
                <h4>Welcome Back</h4>
                <Form onSubmit={handleSubmit}>
                    <br/>
                    <Form.Group controlId="formBasicEmail">
                        {/* <Form.Label>Email</Form.Label> */}
                        <Form.Control type="text" placeholder="Enter email" onChange={e => setEmail(e.target.value)} disabled={inputIsDisabled} value={email}/>
                    </Form.Group><br/>
                    <Form.Group controlId="formBasicPassword">
                        {/* <Form.Label>Password</Form.Label> */}
                        <Form.Control type="password" placeholder="Enter password" onChange={e => setPassword(e.target.value)} disabled={inputIsDisabled} value={password}/>
                    </Form.Group><br />               
                    { requiresMfa &&
                        <Form.Group controlId="formMFA">
                            <Form.Label>Enter your MFA code</Form.Label>
                            <MfaInput onChange={setMfaCode} value={mfaCode} />
                        </Form.Group>
                    }
                    <Form.Text className="text-muted">
                        <p>{successMessage}</p>
                    </Form.Text>
                    { (isLoading) ? (<Spinner/>) : (<Button text="Sign In" color="#3D85BB" textColor="white" type="submit"/>)}
                </Form>
                <br/>
                <Link to={'/requestpasswordreset'}>Forgot your password?</Link>
                </Wrap>
            </GlassContainer>
            </div>
            </div>
        </PageContainer>
     );
}

const PageContainer = styled.section`
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
`;

const Wrap = styled.div`
    background-color: rgba(250, 250, 250, 0.6);
    position: relative;
    height: 100%;
    min-height: 290px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    border-radius: 10px;

    a {
        color: #007bff;
        font-size: 13px;
        font-style: underline;
    }

    h4 {
        color: #3D85BB;
    }
`;

export default Login;