import React from 'react';
import ListView from './ListView';
import AdminFunctionPanel from './AdminFunctionPanel';
import styled from 'styled-components';
import { getUsersAdmin, createUserAdmin, deleteUserAdmin, inviteUser, performHijack } from '../services/api/admin_users';
import NewEntry from './NewEntry';
import { Button } from 'react-bootstrap';


const AdminUsers = (props) => {

    let attributes = [
        {
            "key": "id",
            "label": "ID",
            "type": "copy",
            "settable": false,
            "filterable": true,
        },
        {
            "key": "email",
            "label": "Email",
            "type": "string",
            "settable": true,
            "filterable": true,
            "required": true,
            "detailLink": "/admin/users/<id>/",
            "validator": (value) => {
                const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
                if (!emailRegex.test(value)) {
                    return "Invalid email address";
                }
                return null;
            }
        },
        {
            "key": "first_name",
            "label": "First Name",
            "type": "string",
            "settable": true,
            "filterable": true,
            "required": true,
        },
        {
            "key": "last_name",
            "label": "Last Name",
            "type": "string",
            "settable": true,
            "filterable": true,
            "required": true,
        },
        {
            "key": "account_type",
            "label": "Account Type",
            "type": "choice",
            "choices": [
                {
                    "value": "surgeon",
                    "label": "Surgeon",
                },
                {
                    "value": "organization",
                    "label": "Organization",
                },
            ],
            "settable": true,
            "filterable": true,
            "required": true,
        },
        {
            "key": "tier",
            "label": "Tier",
            "type": "choice",
            "choices": [
                {
                    "value": "free",
                    "label": "Free",
                },
                {
                    "value": "pro",
                    "label": "Professional",
                },
            ],
            "settable": true,
            "filterable": true,
        },
        {
            "key": "is_admin",
            "label": "Admin?",
            "type": "boolean",
            "settable": true,
            "filterable": true,
        },
        {
            "key": "is_active",
            "label": "Active?",
            "type": "boolean",
            "settable": false,
            "filterable": true,
        },
    ];

    let list_attributes = [
        ...attributes,
    ];

    let new_attributes = [
        ...attributes,
        {
            "key": "is_confirmed",
            "label": "Email Confirmed?",
            "type": "boolean",
            "settable": true,
        },
        {
            "key": "password",
            "label": "Password",
            "type": "password",
            "settable": true,
            "required": true,
        },
    ];

    const exportUsers = (e) => {
        let users = [];
        let pageNumber = 0;
        let running = true;

        while (running) {
            pageNumber += 1;

            getUsersAdmin({page: pageNumber}).then((response) => {
                if (response.length === 0) {
                    running = false;
                }

                response.map((user) => {
                    users.push({
                        "ID": user.id,
                        "Email": user.email,
                        "First Name": user.first_name,
                        "Last Name": user.last_name,
                        "Account Type": user.account_type,
                        "Tier": user.tier,
                        "Admin?": user.is_admin,
                        "Active?": user.is_active,
                    });
                });
            }).catch((error) => {
                running = false;
            });
        }

        let csv = "data:text/csv;charset=utf-8," + users.map((user) => {
            return Object.values(user).join(",");
        }).join("\n");

        let encodedUri = encodeURI(csv);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "users.csv");
        document.body.appendChild(link);
        link.click();
    }

    return (
        <Page>
            <AdminFunctionPanel
                actions={[
                    {"label": "Export", "onClickFunction": exportUsers},
                ]}
                />
            <ListView
                title="Users"
                list_method={getUsersAdmin}
                delete_method={deleteUserAdmin}
                attributes={list_attributes}
                paginate={true}
                max_per_page={50} />
            <NewEntry
                title="User"
                create_method={createUserAdmin}
                attributes={new_attributes} />
        </Page>
    )
};

export default AdminUsers;

const Page = styled.div`
    position: absolute;
    flex-flow: column nowrap;
    top: 70px;
    display: flex;
    height: calc(100vh - 70px);
    width: 100vw;
    align-items: center;
    overflow: auto;
`;
