import React, { useEffect, useRef } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";
import * as cases from '../services/api/cases.js';
import * as attachments from '../services/api/attachments.js';
import axios from "axios";
import ProgressBar from 'react-bootstrap/ProgressBar';
import DropFiles from "./DropFiles.js";
import { getVideoFrame, getVideoMetadata } from "../services/util/video.js";
import Button from 'react-bootstrap/Button';
import { combineMultipartVideoData } from "../services/util/video.js";
import { useWakeLock } from "react-screen-wake-lock";
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import SymbolBubble from "./SymbolBubble.js";
import * as Sentry from "@sentry/react";
import mixpanel from 'mixpanel-browser';
import { hmsTextFromSeconds } from "../services/util/metadata.js";
import { useDispatch, useSelector } from "react-redux";

const Upload = (props) => {

    const { isSupported, released, request, release } = useWakeLock({
        onRequest: () => console.log('Screen Wake Lock: requested!'),
        onError: () => console.log('An error happened 💥'),
        onRelease: () => {
            if (isUploading.some(item => item === true))
                setShowScreenLockAlert(true);
        },
    });

    const [showFileErrorModal, setShowFileErrorModal] = useState(false);

    const [showUploadingAlert, setShowUploadingAlert] = useState(false);
    const [showUploadCompleteAlert, setShowUploadCompleteAlert] = useState(false);
    const [showUploadFailedAlert, setShowUploadFailedAlert] = useState(false);
    const [showScreenLockAlert, setShowScreenLockAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [fileList, setFileList] = useState([]);
    const [caseNames, setCaseNames] = useState([]);
    const [caseDescriptions, setCaseDescriptions] = useState([]);
    const [thumbnailURLs, setThumbnailURLs] = useState([]);
    const [uploadProgress, setUploadProgress] = useState([]);
    const [isUploading, setIsUploading] = useState([]);
    const [uploadCompleted, setUploadCompleted] = useState([]);
    const [videoLengths, setVideoLengths] = useState();

    useEffect(() => {
        if (isSupported) {
            //if any files are uploading, request the screen lock
            if (isUploading.some(item => item === true) && (released === undefined || released === true))
                request();
            //if all uploads are complete, release screen lock
            else if (isUploading.every(item => item === false) && released === false)
                release();
        }

        if (uploadCompleted.every(item => item === true) && 
            showUploadCompleteAlert === false && 
            showUploadFailedAlert === false &&
            uploadCompleted.length > 0)
            setShowUploadCompleteAlert(true);

    }, [isUploading])

    async function handleDropFiles(files) {

        //determine whether each file is an mp4 and store the results in an array
        const isMP4 = [...files].map( (file) => {
            return file.type === 'video/mp4';
        });
        

        //determine whether any file is larger than 5GB and store the results in an array
        const isTooLarge = [...files].map( (file) => {
            return file.size / 1024**3 > 5;
        });

        //if any file is not an mp4 or is too large, alert the user
        if (isMP4.some(item => item === false) || isTooLarge.some(item => item === true)) {
            setShowFileErrorModal(true);
        }

        //build file array with multipart files combined into lists
        const continuousFiles = await combineMultipartVideoData([...files].filter((file, idx) => isMP4[idx] && !isTooLarge[idx]));
        setFileList(continuousFiles);

        //set case names, case descriptions, upload status, upload progress, and thumbnail urls
        setCaseNames(() => []);
        setThumbnailURLs(() => []);

        setCaseDescriptions(() => Array(continuousFiles.length).fill(''));
        setIsUploading(() => Array(continuousFiles.length).fill(false));
        setUploadCompleted(() => Array(continuousFiles.length).fill(false));
        
        let tempVideoLengths = [];
        let tempUploadProgress = [];  
        // let tempThumbnailURLs = thumbnailURLs;
        for (let idx = 0; idx < continuousFiles.length; idx++) {
            console.log(continuousFiles[idx].name)
            tempUploadProgress.push(Array(continuousFiles[idx].length).fill(0));
            setCaseNames(caseNames => [...caseNames, continuousFiles[idx][0].name.split('.')[0]]);
            getVideoFrame(URL.createObjectURL(continuousFiles[idx][0]), 0, (dataURL) => {
                // thumbnailURLs[idx] = dataURL;
                setThumbnailURLs(thumbnailURLs => [
                    ...thumbnailURLs.slice(0,idx), 
                    dataURL, 
                    ...thumbnailURLs.slice(idx)]);
            });
            tempVideoLengths.push([]);
            for (let jdx = 0; jdx < continuousFiles[idx].length; jdx++) {
                getVideoMetadata(continuousFiles[idx][jdx]).then((metadata) => {
                    tempVideoLengths[idx].push(metadata.duration);
                })
            }
        }
        setVideoLengths(() => tempVideoLengths);
        setUploadProgress(() => tempUploadProgress);
        // setThumbnailURLs(() => tempThumbnailURLs);
    }

    const handleRemoveItem = (index) => {
        console.log('removing item ' + index)
        setFileList([...fileList.slice(0, index), ...fileList.slice(index + 1)]);
        setCaseNames([...caseNames.slice(0, index), ...caseNames.slice(index + 1)]);
        setThumbnailURLs([...thumbnailURLs.slice(0, index), ...thumbnailURLs.slice(index + 1)]);
        setCaseDescriptions([...caseDescriptions.slice(0, index), ...caseDescriptions.slice(index + 1)]);
        setUploadProgress([...uploadProgress.slice(0, index), ...uploadProgress.slice(index + 1)]);
        setIsUploading([...isUploading.slice(0, index), ...isUploading.slice(index + 1)]);
        setUploadCompleted([...uploadCompleted.slice(0, index), ...uploadCompleted.slice(index + 1)]);
        setVideoLengths([...videoLengths.slice(0, index), ...videoLengths.slice(index + 1)]);
    }

    const handleUploadAll= () => {
        fileList.forEach((item, idx) => {
            handleUploadSingle(idx);
        });

        //reset alerts
        setShowUploadingAlert(() => false);
        setShowUploadCompleteAlert(() => false);
        setShowUploadFailedAlert(() => false);

        //set all upload complete to false
        setUploadCompleted(() => Array(fileList.length).fill(false));
        setShowUploadingAlert(() => true);

        mixpanel.track('Clicked Upload Video', {'Source': 'Upload Page'})
    }

    const handleUploadSingle = (idx) => {
        if (isUploading[idx])
            return;
        setIsUploading(isUploading => [...isUploading.slice(0, idx), true, ...isUploading.slice(idx + 1)]);
        uploadFile(idx);
    }

    const uploadFile = (idx) => {

        let attachment_id;
        let videoLength = videoLengths[idx].reduce((a, b) => a + b, 0)

        cases.createCase(
            {
                'case_name': caseNames[idx], 
                'description': caseDescriptions[idx],
            }
        )
        .then(data => data['id'])
        .then(case_id => attachments.createAttachment(
            {
                'case_id': case_id,
                'user_metadata': { 
                    'duration': videoLength
                }
            }
        ))
        .then(data => {
            attachment_id = data['id']
            return attachment_id
        })
        .then(() => attachments.updateAttachmentStatus(attachment_id, {'status': 1}))
        .then(() => {
            console.log('uploading thumbnail for attachment ' + attachment_id)
            fetch(thumbnailURLs[idx]).then(res => res.blob()).then((blob) => {
                attachments.uploadThumbnail(attachment_id, blob);
            })
        })
        .then(() => attachments.generateUploadLink(attachment_id, {'segment_count': fileList[idx].length}))
        .then(data => data['upload_link'])
        .then((upload_links) => {
            if (!Array.isArray(upload_links))
                upload_links = [upload_links];
            return Promise.all(upload_links.map((upload_link, segment_idx) => {
                return axios.put(upload_link, fileList[idx][segment_idx], {
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent;
                        let percent = Math.floor((loaded * 100) / total)
                        setUploadProgress(uploadProgress => [
                            ...uploadProgress.slice(0, idx), 
                            [...uploadProgress[idx].slice(0, segment_idx), percent, ...uploadProgress[idx].slice(segment_idx + 1)], 
                            ...uploadProgress.slice(idx + 1)]);
                    },
                    headers: {
                        'Content-Type': 'video/mp4',
                    }
                })
            }))
        })
        .then(result => {
            if (result.every(item => item.status === 200)) {
                console.log('upload successful');
                setIsUploading(isUploading => [...isUploading.slice(0, idx), false, ...isUploading.slice(idx + 1)]);
                setUploadCompleted(uploadCompleted => [...uploadCompleted.slice(0, idx), true, ...uploadCompleted.slice(idx + 1)]);
                attachments.updateAttachmentStatus(attachment_id, {'status': 2});
                mixpanel.track('Uploaded Video', {'Source': 'Upload Page', 'Length': videoLength})
            }
            else {
                setIsUploading(isUploading => [...isUploading.slice(0, idx), false, ...isUploading.slice(idx + 1)]);
                setShowUploadFailedAlert(true);
                console.log('Upload failed, but no error was detected.');
                Sentry.captureMessage('Upload failed, but no error was detected.');
                setErrorMessage('One or more of your videos failed to upload successfully. Please try again. If this problem persists, please contact support.');

            }
        })
        .catch(error => {
            if (error) {
                Sentry.captureException(error);
                console.log(error);
                if (error.code="ERR_NETWORK")
                    setErrorMessage('One or more of your videos failed to upload successfully due to a loss of network connectivity. Please check your internet connection and try again.');
            } else {
                Sentry.captureMessage('Upload failed, but no error was detected.');
                console.log('Upload failed, but no error was detected.');
                setErrorMessage('One or more of your videos failed to upload successfully. Please try again. If this problem persists, please contact support.');
            }
            setIsUploading(isUploading => [...isUploading.slice(0, idx), false, ...isUploading.slice(idx + 1)]);
            setUploadProgress(uploadProgress => [
                ...uploadProgress.slice(0, idx), 
                Array(uploadProgress[idx].length).fill(0), 
                ...uploadProgress.slice(idx + 1)]);
            setShowUploadFailedAlert(true);
        })
    }

    return ( 
        <PageContainer>
            <DropFilesContainer>
                <DropFiles
                    label = "Drag and drop your mp4 files here!"
                    handleChange = {(e) => {
                        if (isUploading.some(item => item === true))
                            return;
                        else
                            handleDropFiles(e)
                    }}
                />
            </DropFilesContainer>
            <FileListContainer>
                <FileList>
                        {fileList && (                 
                        fileList.map((file, index) => {
                            return (
                            <UploadItemContainer key={index}>
                                <UploadItem>
                                    <ThumbnailImage>
                                        <img src={thumbnailURLs[index]} alt={file[0].name}/>
                                    </ThumbnailImage>
                                    <CaseInfoContainer>
                                        <span><span>Case Name:</span>
                                        <SymbolBubble
                                            text="This information will be visible to users with whom you share the video."
                                            variant="warning"
                                        />
                                        </span>
                                        <CaseName 
                                            value={caseNames[index]} 
                                            type='text' 
                                            disabled={isUploading[index]}
                                            onChange={(e) => {
                                            return setCaseNames(caseNames.map(
                                                (item, idx) => idx === index ? e.target.value : item
                                            ))}}/>
                                        <span>
                                            {fileList[index].length} file{fileList[index].length > 1 ? 's':''}, {hmsTextFromSeconds(videoLengths[index].reduce((a, b) => a + b, 0), 'hm')}
                                        </span>
                                        <span><span>Case Description:</span>
                                        <SymbolBubble
                                            text="This information will be visible to users with whom you share the video."
                                            variant="warning"
                                        />
                                        </span>
                                        <CaseDescription 
                                            value={caseDescriptions[index]} 
                                            type='text'
                                            disabled={isUploading[index]} 
                                            onChange={(e) => {
                                            return setCaseDescriptions(caseDescriptions.map(
                                                (item, idx) => idx === index ? e.target.value : item
                                            ))}}/>
                                    </CaseInfoContainer>                        
                                </UploadItem>
                                <UploadInfoContainer>
                                    {/* <StyledProgressBar> */}
                                        {/* {uploadProgress[index].map((item, idx) => {
                                            return <StyledProgressBar key={idx} now={item} label={`${item}%`} />
                                        })} */}
                                        <ProgressBarContainer>
                                        <StyledProgressBar 
                                            now={Math.round(uploadProgress[index].reduce((a, b) => a + b, 0)/uploadProgress[index].length)} 
                                            // label={`${Math.round(uploadProgress[index].reduce((a, b) => a + b, 0)/uploadProgress[index].length)}%`}
                                            animated={isUploading[index]}
                                        />
                                        <ProgressPercentage>
                                            {`${Math.round(uploadProgress[index].reduce((a, b) => a + b, 0)/uploadProgress[index].length)}%`}
                                        </ProgressPercentage>
                                        </ProgressBarContainer>
                                    {/* { !isUploading[index] && <ControlButton>{String('\u21EB')}</ControlButton> } */}
                                    { (!isUploading[index] && !uploadCompleted[index]) && <ControlButton onClick = {() => handleRemoveItem(index)}>X</ControlButton> }
                                </UploadInfoContainer>
                            </UploadItemContainer>
                            )}
                        ))}
                { fileList.length > 0 && 
                    <Button 
                        onClick={handleUploadAll} 
                        disabled={isUploading.some(item => item === true)}
                    >
                        Upload All
                    </Button>
                }
                </FileList>
                <AlertContainer>
                    <Alert show={showUploadCompleteAlert} variant='success' onClose={() => setShowUploadCompleteAlert(false)} dismissible>
                        <Alert.Heading>Upload Complete</Alert.Heading>
                        <p>
                            Your videos have been successfully uploaded. They will be available in your library after brief processing. You may now continue to use this window normally.
                        </p>
                    </Alert>
                    <Alert show={showUploadFailedAlert} variant='danger' onClose={() => setShowUploadFailedAlert(false)} dismissible>
                        <Alert.Heading>Upload Failed</Alert.Heading>
                        <p>
                            {errorMessage}
                        </p>
                    </Alert>
                    <Alert show={showScreenLockAlert} variant='warning' onClose={() => setShowScreenLockAlert(false)} dismissible>
                        <Alert.Heading>Orchid is not able to keep your computer awake</Alert.Heading>
                        <p>
                            Please check your computer's sleep settings, and stay on this page while the upload is in progress.
                        </p>
                    </Alert>
                    <Alert show={showUploadingAlert} variant='primary' onClose={() => setShowUploadingAlert(false)} dismissible>
                        <Alert.Heading>Upload Started</Alert.Heading>
                        <p>
                            {isSupported ? 'Orchid will attempt to keep your computer awake while the upload is in progress. ' : "Your computer's sleep settings may interfere with the upload. "}
                            For best results, ensure your computer is plugged in and stay on this page until the upload is complete.
                        </p>
                    </Alert>
                </AlertContainer>
            </FileListContainer>
            <Modal 
                show={showFileErrorModal} 
                onHide={() => setShowFileErrorModal(false)}
                centered
                size='md'
                >
                <Modal.Header closeButton>
                    <Modal.Title>Uh Oh...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Currently, Orchid only supports uploads of mp4 files that are individually less than 5GB in size.
                        Orchid will automatically stitch together multipart videos from the Orchid camera once the files
                        have been uploaded.
                        <br/><br/>
                        One or more of the files you selected is either too large, or is not an mp4 file.
                        These files have been removed from your upload list. 
                        <br/><br/>
                        We are always working to improve our service. If you have any questions or concerns, 
                        please contact <u><a href="mailto:contact@orchidsurgical.com"> support</a></u>.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowFileErrorModal(false)}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
     );
}
 
const PageContainer = styled.div`
    padding: 12px;
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 1920px;  
`;

const DropFilesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20vw;
`;

const FileListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 70vw;
    /* background-image: url(/images/logo_nobkg_a70.png); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70% auto;
    /* overflow-y: auto;
    overflow-x: hidden; */
`;

const FileList = styled.div`
    margin-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const ProgressBarContainer = styled.div`
    position: relative;
`;

const StyledProgressBar = styled(ProgressBar)`
    background: #cccccc;
    `;

const UploadItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    div {
        width: 100%;
        /* min-width: 20px; */
    }
`;

const UploadItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`;  

const ThumbnailImage = styled.div`
    width: 40vw;
    height: 100%;
    max-width: 200px;
    border-radius: 10px;
    /* box-shadow: rgb(0 0 0 / 49%) 0px 26px 30px -10px, 
            rgb(0 0 0 /53%) 0px 16px 10px -10px; */
    cursor: pointer;
    overflow: hidden;
    position: relative;
    border: 3px solid rgba(249, 249, 249, .1);

    img {
        inset: 0px;
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 1;
        top: 0;
    }
`;

const CaseInfoContainer = styled.div`
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 150px;
    width: 100%;

    span {
        display: inline-flex;
        flex-direction: row;
        white-space: nowrap;
        align-items: center;
        justify-content: flex-start;
    }
`;

const CaseName = styled.input`
    width: 100%;
`;

const CaseDescription = styled.textarea`
    height: 200px;
    width: 100%;
    resize: none;
    /* background-color: (295, 295, 295, .9);
    background: transparent; */
`;

const UploadInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    /* background-color: #f5f5f5; */
    width: 100%;
    /* padding: 3px; */
    /* border-radius: 7px;
    font-size: 14px; */

    button {
        margin-left: 3px;
    }
`;

const ControlButton = styled.button`
    border: none;
    border-radius:8px;
    height: 16.5px;
    width: 16.5px;
    text-align: center;
    font-size: 9px;
    color: white;
    font-weight: normal;
    background-color: #007bff;
`

const ProgressPercentage = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    color: white;
    font-size: 12px;
    font-weight: bold;
    /* background-color: transparent; */
    z-index: 2;
`;

const AlertContainer = styled.div`
    margin-top: 20px;
    margin-left: 20px;
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 100;
    font-size: 16px;

    .alert-heading {
        font-size: 20px;
    }

    .alert {
        width: 100%;
        margin-bottom: 10px;
    }
`;

export default Upload;