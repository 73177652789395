import { useEffect, useState } from "react";
import { styled } from "styled-components";
import PropTypes from 'prop-types';


const Search = (props) => {
    /* 
    * Search component that allows users to search for results and select them
    * 
    * Required props:
    * - onSearch: function that takes a search term and returns a promise that resolves to an array of results
    * - resultRenderer: function that takes a result and returns a React component to render it
    * 
    * Optional props:
    * - termValidator: function that takes a search term and returns true if it is valid
    * - placeholder: string to display in the search input
    * - selectedRenderer: function that takes a selected result and returns a React component to render it
    * - resultIdentifier: string key to use as the unique identifier for results
    * - onSelect: function that takes a selected result and is called when a result is selected
    * - onRemove: function that takes a selected result and is called when a result is removed
    * - baseSearchQuery: object to pass as the base query for the search
    */

    const [ term, setTerm ] = useState('');
    const [ results, setResults ] = useState([]);
    const [ selected, setSelected ] = useState([]);
    const validator = props.termValidator || ((term) => term.length >= 3);
    const selectedRenderer = props.selectedRenderer || props.resultRenderer;

    const onInput = (e) => {
        setTerm(e.target.value);
    };

    const isValueSelected = (value) => {
        return selected.map((v) => v[props.resultIdentifier]).includes(value[props.resultIdentifier]);
    };

    // Search for results when the term changes
    useEffect(() => {
        if (!validator(term)) {
            setResults([]);
            return;
        }
        let searchParams = {term: term, ...props.baseSearchQuery};
        props.onSearch(searchParams).then((results) => {
            setResults(results.filter((result) => !isValueSelected(result)));
        });
    }, [term]);

    // Add a result to the selected list and call the onSelect callback
    const onSelected = (result) => {
        if (!isValueSelected(result)) {
            setSelected([...selected, result]);
            if (props.onSelect) props.onSelect(result);
        }
    };

    // Remove a result from the selected list and call the onRemove callback
    const onRemove = (result) => {
        setSelected(selected.filter((v) => v !== result));
        if (props.onRemove) props.onRemove(result);
    };

    return (
        <SearchBar>
            <SearchInput type="text" placeholder={props.placeholder} onInput={onInput} />
            <SelectedValues values={selected}>
                {selected && selected.map((value, index) => {
                    return (
                        <SelectedValue
                            data-id={value[props.resultIdentifier]}
                            onClick={() => onRemove(value)}
                        >
                            {selectedRenderer(value)}
                        </SelectedValue>
                    )
                })}
            </SelectedValues>
            <SearchResults results={results}>
                {results && results.map((result, index) => {
                    return (
                        <SearchResult
                            data-id={result[props.resultIdentifier]}
                            onClick={() => onSelected(result)}
                            selected={isValueSelected(result)}
                        >
                            {props.resultRenderer(result)}
                        </SearchResult>
                    )
                })}
                {results.length === 0 && validator(term) && <NoResults>No results found</NoResults>}
            </SearchResults>
        </SearchBar>
    )
};

export default Search;

Search.propTypes = {
    onSearch: PropTypes.func.isRequired,
    termValidator: PropTypes.func,
    placeholder: PropTypes.string,
    resultRenderer: PropTypes.func.isRequired,
    selectedRenderer: PropTypes.func,
    resultIdentifier: PropTypes.string,
    onSelect: PropTypes.func,
    onRemove: PropTypes.func,
    baseSearchQuery: PropTypes.object,
};

Search.defaultProps = {
    placeholder: "Search...",
    resultIdentifier: "id",
    baseSearchQuery: {},
};

const SearchBar = styled.div`
    width: 100%;
    padding: 10px;
`;

const SearchInput = styled.input`
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
`;

const SearchResults = styled.ul`
    width: 100%;
    margin-top: 5px;
    margin-left: 0px;
    padding-left: 0px;
    display: ${props => props.results.length > 0 ? 'block' : 'none'}
    overflow: auto;
`;

const SearchResult = styled.li`
    cursor: pointer;
    padding: 5px 0px;
    margin: 0px;
    list-style-type: none;

    &:hover {
        background-color: #f0f0f0;
    }

    display: ${props => props.selected ? 'none' : 'block'}
`;

const NoResults = styled.li`
    padding: 5px 0px;
    margin: 0px;
    list-style-type: none;
`;

const SelectedValues = styled.div`
    display: flex;
    flex-flow: row wrap;
`;

const SelectedValue = styled.div`
    font-size: 1em;
    padding: 5px 1em;
    border: 1px solid #ccc;
    border-radius: 1em;
    margin: 5px;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
`;
