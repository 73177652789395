import React, {useState} from "react";
import { styled } from "styled-components";
import Form from 'react-bootstrap/Form';
import { useParams, useNavigate } from "react-router-dom";
import { resetPassword } from "../services/api/authentication";
import Button from '../store/components/Button.js';
import Spinner from 'react-bootstrap/Spinner';
import mixpanel from 'mixpanel-browser';
import { gtag_id, gtag_config } from "../sources.js";
import { setUser } from "../services/redux/userSlice.js";
import { useDispatch } from 'react-redux';
import Header from "../store/components/Header.js";
import { HeaderBackground } from "../store/pages/LandingPage.js";
import { GlassContainer } from "../store/components/GlassContainer.js";
import { useEffect } from "react";

const ResetPassword = (props) => {

    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [passwordErrors, setPasswordErrors] = useState([]);
    const [passwordMatch, setPasswordMatch] = useState(false);

    const navigate = useNavigate();

    let url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    const token = urlParams.get('code');
    const email = urlParams.get('email');

    const validatePassword = () => {
        let pwErrors = [];

        if (password) {
            // check password meets requirements
            if (password.length < 8) {
                pwErrors.push('Password must be at least 8 characters long.');
            }
            if (!password.match(/[A-Z]/)) {
                pwErrors.push('Password must contain at least one uppercase letter.');
            }
            if (!password.match(/[a-z]/)) {
                pwErrors.push('Password must contain at least one lowercase letter.');
            }
            if (!password.match(/[0-9]/)) {
                pwErrors.push('Password must contain at least one number.');
            }
            if (!password.match(/[!@#$%^&*]/)) {
                pwErrors.push('Password must contain at least one special character.');
            }

            setPasswordErrors(pwErrors);
        }

        if (password && confirmPassword) {
            // check passwords match
            if (password !== confirmPassword) {
                setPasswordMatch(false);
            } else {
                setPasswordMatch(true);
            }
        }
    }

    useEffect(() => {
        validatePassword();
    }, [password, confirmPassword]);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        validatePassword();
    }

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        validatePassword();
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            resetPassword(email, token, password).then(data => {
                if (data) {
                    setSuccessMessage("Your password has been reset.")
                    setPassword("");
                    setConfirmPassword("");
                    setTimeout(() => {
                        navigate('/login/');
                    }
                    , 1000);
                }
                else {
                    setSuccessMessage("")
                    setErrorMessage("There was an error resetting your password. \n Please try again.")
                }
            })
        } catch (error) {
            setSuccessMessage("")
            setErrorMessage("There was an error resetting your password. \n Please try again.")
        }
    }


    return ( 
        <PageContainer>
        <HeaderBackground>
                <img src="/images/store/Landing/landing_bkg.svg" alt="background shapes" />
            </HeaderBackground>
            <Header />
            {/* <div style={{display: 'flex', height: "max(50vh, 300px)", alignSelf: "center"}}> */}
            <div style={{display: 'flex', height: "100%", alignItems: "center"}}>
            <div style={{display: 'flex', minHeight: "50vh", width: "60vw"}}>
            <GlassContainer>
            <Wrap>
            <FormWrapper>
            <Form onSubmit={handleSubmit}>
                <b>Please choose a new password:</b>
                <Form.Group controlId="formBasicEmail">
                    <br/>
                    <Form.Control type="password" placeholder="New Password" onInput={(e) => {setPassword(e.target.value)}} />
                    { password && passwordErrors.length > 0 ? (
                        <div>
                            <ErrorList>
                            {passwordErrors.map((error, index) => (
                                <ErrorItem key={index}>{error}</ErrorItem>
                            ))}
                            </ErrorList>
                        </div>
                    ) : (null)}
                </Form.Group><br/>
                <Form.Group controlId="formBasicEmail">
                    <Form.Control type="password" placeholder="Confirm New Password" onInput={(e) => {setConfirmPassword(e.target.value)}} />
                    { password && !passwordMatch ? (
                        <div>
                            <ErrorList>
                                <ErrorItem>Passwords do not match.</ErrorItem>
                            </ErrorList>
                        </div>
                    ) : (null)}
                </Form.Group><br/>
                <Button 
                    text="Reset Password" 
                    color="#3D85BB" 
                    textColor="white" 
                    type="submit" 
                    disabled={!passwordErrors.length === 0 || !passwordMatch}
                    />
            </Form>
            <br/>
            <p>{successMessage}{errorMessage}</p>
            </FormWrapper>
            </Wrap>
        </GlassContainer>
        </div>
        </div>
    </PageContainer>

     );
}

const PageContainer = styled.section`
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
`;

const Wrap = styled.div`
    background-color: rgba(250, 250, 250, 0.6);
    position: relative;
    height: 100%;
    min-height: 290px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    border-radius: 10px;

    a {
        color: #007bff;
        font-size: 13px;
        font-style: underline;
    }

    h4 {
        color: #3D85BB;
    }
`;

const FormWrapper = styled.div`
    width: 400px;
`;

const ErrorList = styled.ul`
    list-style-type: none;
    padding-top: 5px;
    font-size: 0.8rem;
`;

const ErrorItem = styled.li`
    list-style-type: '❌ ';
    color: red;
`;

export default ResetPassword;