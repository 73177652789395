import React from "react";
import styled from "styled-components";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import Header from "../components/Header";
import VideoDemo from "../components/VideoDemo";
import MobileVideoDemo from "../components/MobileVideoDemo";
import Footer from "../components/Footer";
import { GlassContainer } from "../components/GlassContainer";
import { Link, useNavigate } from "react-router-dom";
import Form from "../components/Form";
import useScrollReporter from "../../hooks/useScrollReporter";

const LandingPage = () => {

  const navigate = useNavigate();

  useScrollReporter('Landing Page')

  React.useEffect(() => {
    document.title = "Orchid Cloud";
  }, [])

  return (
    <>
    <MobileStyledLandingPage>
      <HeaderBackground>
        <img src="/images/store/Landing/landing_bkg.svg" alt="background shapes" />
      </HeaderBackground>
      <Header />
      <Panel>
        <div className="text-container">
          <div style={{fontSize: '64px', lineHeight: '70px'}}>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              One Platform For 
            </span>
            <span style={{color: "#3D85BB", fontFamily: "Poppins", fontWeight: "700"}}>
              <wbr/> Surgical Video
            </span>
          </div>
          <div style={{textAlign: 'left'}}>
          <span style={{
            textAlign: "left",
            color: '#4A4A4A',
            fontSize: 18,
            fontFamily: 'Lato',
            fontWeight: '400',
            textTransform: 'capitalize',
          }}> 
            <b>Orchid Surgical</b> makes the toolkit for surgicial video. Simplifying capture, secure storage, and collaboration.
          </span>
          </div>
          <Form 
            placeholder="Your Email" 
            text="Book A Demo" 
            buttonColor="#3D85BB"
            buttonTextColor="#ffffff"
            width="90%"
            maxWidth="400px"
            formName="LIST"
            formValue="WaitList"
          />
          <div style={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: '500'}}>
            <span style={{color: '#BFBFBF'}}>Already On Orchid Cloud? </span>
            <StyledLink to={'/login'}> Sign In</StyledLink>
          </div>
        </div>
        <div className="content-container">
            <MobileVideoDemo videos={[
              {
                "title": "CABG",
                "date": "11/14/2023",
                "duration": "4h 35m",
                "tags": ["Cardio", "tag2", "tag3"],
                "author": "Jeremy Morales",
                "thumbnail": "/images/store/Landing/cabg_thumbnail.png",
                "chapters": 12,
                "vidpath": "/videos/cabg_demo.mp4"
              },
              {
                "title": "Total Hip Arthroplasty",
                "date": "1/6/2024",
                "duration": "47m",
                "tags": ["Ortho", "tag2", "tag3"],
                "author": "Gary White",
                "thumbnail": "/images/store/Landing/hip_thumbnail.png",
                "chapters": 5,
                "vidpath": "/videos/tha_demo.mp4"
              },
              {
                "title": "Free Flap Elevation",
                "date": "2/27/2024",
                "duration": "3h 2m",
                "tags": ["Plastic", "tag2", "tag3"],
                "author": "Sara McIntosh",
                "thumbnail": "/images/store/Landing/diep_thumbnail.png",
                "chapters": 9,
                "vidpath": "/videos/diep_demo.mp4"
              }
            ]}
            />
        </div>
      </Panel>
      <Panel>
        <div className="text-container">
        <div style={{fontSize: '60px', lineHeight: '70px'}}>
            <span style={{color: "#3B418B", fontFamily: "Poppins", fontWeight: "700"}}>
              Hit Record
            </span>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              <wbr/> And You're Good To Go
            </span>
          </div>
         </div>
        <div className="content-container">
          <BackgroundShapes src="/images/store/Landing/camera_bkg.svg" alt="background shapes"/>
          <img src="/images/camera_improved_render.png" alt="The Orchid head-mounted surgical camera" style={{width: '100%', maxWidth: '400px'}} />
        </div>
        <div className="text-container">
          <div style={{textAlign: 'left'}}>
            <span style={{
              textAlign: "left",
              color: '#4A4A4A',
              fontSize: 18,
              fontFamily: 'Lato',
              fontWeight: '400',
              textTransform: 'none',
            }}> 
              Finally, an affordable camera <b>designed for surgeons</b>. Tuned for the high-contrast OR environment. Comfortable enough that you can record all day, every day.
            </span>
          </div>
          {/* <Button text="Coming Soon: Orchid Cameras &rarr;" color="#5f67d894" textColor="white" /> */}
        </div>
      </Panel>
      <Panel>
        <div className="text-container">
          <div style={{fontSize: '60px', lineHeight: '70px'}}>
            <span style={{color: "#701D15", fontFamily: "Poppins", fontWeight: "700"}}>
              Upload Cases
            </span>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              <wbr/> To Your Heart's Content
            </span>
          </div>
        </div>
        <div className="content-container">
          <div style={{position: 'relative', height: '300px', width: '100%'}}>
          <BackgroundShapes src="/images/store/Landing/upload_bkg.svg" alt="background shapes"/>
          <img src="/images/store/Landing/Sample_Case_1.png" alt="upload" 
            style={{height: '80px', position: 'absolute', top: 0, left: '50%', transform: 'translate(-50%, -50%)'}}/>
          <img src="/images/store/Landing/Sample_Case_2.png" alt="upload" 
            style={{height: '83px', position: 'absolute', top: '80px', left: '60%', transform: 'translate(-50%, -50%)'}}/>
          <img src="/images/store/Landing/Sample_Case_3.png" alt="upload" 
            style={{height: '83px', position: 'absolute', top: '160px', left: '40%', transform: 'translate(-50%, -50%)'}}/>
          <img src="/images/store/Landing/Sample_Case_4.png" alt="upload" 
            style={{height: '80px', position: 'absolute', top: '240px', left: '50%', transform: 'translate(-50%, -50%)'}}/>
          </div>
        </div>
        <div className="text-container">
          <div style={{textAlign: 'left'}}>
            <span style={{
              textAlign: "left",
              color: '#4A4A4A',
              fontSize: 18,
              fontFamily: 'Lato',
              fontWeight: '400',
              textTransform: 'none',
            }}> 
            So, you love our Camera — but what will you do with hundreds of hours of operative footage?
            Orchid cloud has you covered with <b>unlimited HIPAA-Compliant cloud storage </b>
            for your videos, where data safety and reliability are top priorities. 
            </span>
          </div>
        </div>
      </Panel>
      <Panel>
        <div className="text-container">
          <div style={{fontSize: '60px', lineHeight: '70px'}}>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              Easily
            </span>
            <span style={{color: "#6A4711", fontFamily: "Poppins", fontWeight: "700"}}>
              <wbr/> Enrich
            </span>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              <wbr/> Your Cases
            </span>
          </div>
        </div>
        <div className="content-container">
          <BackgroundShapes src="/images/store/Landing/enrich_bkg.svg" alt="background shapes"/>
          <div style={{position: 'relative', width: '100%', height: '200px'}}>
          <img src="/images/store/Landing/segment_image.png" alt="Edit videos of Surgery" 
            style={{height: '200px', position: 'absolute', top: 0, left: 'calc(50% - 0px)', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/segment1.png" alt="upload" 
            style={{height: '40px', position: 'absolute', top: 10, left: 'calc(80%)', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/segment2.png" alt="upload" 
            style={{height: '40px', position: 'absolute', top: 52, left: 'calc(80%)', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/segment3.png" alt="upload" 
            style={{height: '40px', position: 'absolute', top: 94, left: 'calc(80%)', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/addnew.png" alt="upload" 
            style={{height: '25px', position: 'absolute', top: 150, left: 'calc(80%)', transform: 'translate(-50%, 0%)'}}/>
          </div>
        </div>
        <div className="text-container">
          <div style={{textAlign: 'left'}}>
          <span style={{
            textAlign: "left",
            color: '#4A4A4A',
            fontSize: 18,
            fontFamily: 'Lato',
            fontWeight: '400',
            textTransform: 'none',
          }}> 
            Using Orchid’s Case segmentation tool, break down your video into <b>clickable chapters</b> and make it easy to navigate, without the hassle of editing. Apply Case templates with one click, and compare steps across similar procedures.
          </span>
          </div>
        </div>
      </Panel>
      <Panel>
        <div className="text-container">
          <div style={{fontSize: '60px', lineHeight: '70px'}}>
            <span style={{color: "#0C3C83", fontFamily: "Poppins", fontWeight: "700"}}>
              Share
            </span>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              <wbr/> With Your Peers
            </span>
          </div>
        </div>
        <div className="content-container">
          <BackgroundShapes src="/images/store/Landing/Share_bkg.svg" alt="background shapes" />
          <div style={{position: 'relative', width: '100%', height: '300px'}}>
          <img src="/images/store/Landing/message_image.png" alt="Share videos of surgery" 
            style={{height: '180px', position: 'absolute', top: 0, left: '55%', transform: 'translate(-50%, 0%)'}} />
          <img src="/images/store/Landing/message2.png" alt="Exchange surgical knowledge with your peers" 
            style={{height: '70px', position: 'absolute', top: '110px', left: '49%', transform: 'translate(-50%, 50%)'}}/>
          <img src="/images/store/Landing/message3.png" alt="Institutional memory for surgical residencies" 
            style={{height: '70px', position: 'absolute', top: '150px', left: 'calc(70% - 80px)', transform: 'translate(-50%, 50%)'}} />
          <img src="/images/store/Landing/message1.png" alt="Digital education for surgical residents" 
            style={{height: '70px', position: 'absolute', top: '190px', left: 'calc(37% + 50px)', transform: 'translate(-50%, 50%)'}} />
          </div>
        </div>
        <div className="text-container">
        <div style={{textAlign: 'left'}}>
            <span style={{
              textAlign: "left",
              color: '#4A4A4A',
              fontSize: 18,
              fontFamily: 'Lato',
              fontWeight: '400',
              textTransform: 'none',
            }}> 
              Say Goodbye to transferring enormous video files. Once your cases are on orchid, sharing with peers and colleagues couldn’t be easier. Whether you are part of an orchid group, or just want to DM a case to a friend, Orchid makes collaboration secure and simple.
            </span>
          </div>
        </div>
      </Panel>
      <PricingPanel>
        <div className="background-left">
          <img src="/images/store/Landing/big_triangles_left.svg" alt="background shapes" />
        </div>
        <div className="background-right">
          <img src="/images/store/Landing/big_triangles_right.svg" alt="background shapes" />
        </div>
        <div className="call-to-action">
          <div style={{textAlign: 'center', fontSize: '60px', lineHeight: '70px'}}>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              Ready to Get Started?
            </span>
          </div>
          <Form
            placeholder="Your Email" 
            text="Book A Demo" 
            buttonColor="#3D85BB"
            buttonTextColor="#ffffff"
            width="90%"
            maxWidth="400px"
            formName="LIST"
            formValue="Waitlist"
          />
          <div style={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: '500'}}>
            <span style={{color: '#BFBFBF'}}>Already On Orchid Cloud? </span>
            <StyledLink to={'/login/'}> Sign In</StyledLink>
          </div>
        </div>
    </PricingPanel>
      <Footer/>
    </MobileStyledLandingPage>
    <StyledLandingPage>
      <HeaderBackground>
        <img src="/images/store/Landing/landing_bkg.svg" alt="background shapes" />
      </HeaderBackground>
      <Header />
      <Panel>
        <div className="text-container">
          <div style={{fontSize: '68px', lineHeight: '70px'}}>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              One Platform For 
            </span>
            <span style={{color: "#3D85BB", fontFamily: "Poppins", fontWeight: "700"}}>
              <wbr/> Surgical Video
            </span>
          </div>
          <div style={{textAlign: 'left'}}>
          <span style={{
            textAlign: "left",
            color: '#4A4A4A',
            fontSize: 18,
            fontFamily: 'Lato',
            fontWeight: '400',
            textTransform: 'capitalize',
          }}> 
            <b>Orchid Surgical</b> makes the toolkit for surgicial video. Simplifying capture, secure storage, and collaboration.
          </span>
          </div>
          <Form 
            placeholder="Your Email" 
            text="Book A Demo" 
            buttonColor="#3D85BB"
            buttonTextColor="#ffffff"
            width="400px" 
            formName="LIST"
            formValue="WaitList"
          />
          <div style={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: '500'}}>
            <span style={{color: '#BFBFBF'}}>Already On Orchid Cloud? </span>
            <StyledLink to={'/login'}> Sign In</StyledLink>
          </div>
        </div>
        <div className="content-container">
          <GlassContainer style={{zIndex: 10, height: '460px'}}>
            <VideoDemo videos={[
              {
                "title": "CABG",
                "date": "11/14/2023",
                "duration": "4h 35m",
                "tags": ["Cardio", "tag2", "tag3"],
                "author": "Jeremy Morales",
                "thumbnail": "/images/store/Landing/cabg_thumbnail.png",
                "chapters": 12,
                "vidpath": "/videos/cabg_demo.mp4"
              },
              {
                "title": "Total Hip Arthroplasty",
                "date": "1/6/2024",
                "duration": "47m",
                "tags": ["Ortho", "tag2", "tag3"],
                "author": "Gary White",
                "thumbnail": "/images/store/Landing/hip_thumbnail.png",
                "chapters": 5,
                "vidpath": "/videos/tha_demo.mp4"
              },
              {
                "title": "Free Flap Elevation",
                "date": "2/27/2024",
                "duration": "3h 2m",
                "tags": ["Plastic", "tag2", "tag3"],
                "author": "Sara McIntosh",
                "thumbnail": "/images/store/Landing/diep_thumbnail.png",
                "chapters": 9,
                "vidpath": "/videos/diep_demo.mp4"
              }
            ]}
            />
          </GlassContainer>
        </div>
      </Panel>
      <Panel>
        <div className="content-container">
          <BackgroundShapes style={{height:'350px'}} src="/images/store/Landing/camera_bkg.svg" alt="background shapes"/>
          <img src="/images/camera_improved_render.png" alt="The Orchid head-mounted surgical camera" height="320px" />
        </div>
        <div className="text-container">
          <div style={{fontSize: '60px', lineHeight: '70px'}}>
            <span style={{color: "#3B418B", fontFamily: "Poppins", fontWeight: "700"}}>
              Hit Record
            </span>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              <wbr/> And You're Good To Go
            </span>
          </div>
          <div style={{textAlign: 'left'}}>
            <span style={{
              textAlign: "left",
              color: '#4A4A4A',
              fontSize: 18,
              fontFamily: 'Lato',
              fontWeight: '400',
              textTransform: 'none',
            }}> 
              Finally, an affordable camera <b>designed for surgeons</b>. Tuned for the high-contrast OR environment. Comfortable enough that you can record all day, every day.
            </span>
          </div>
          {/* <Button text="Coming Soon: Orchid Cameras &rarr;" color="#5f67d894" textColor="white" /> */}
        </div>
      </Panel>
      <Panel>
        <div className="text-container">
          <div style={{fontSize: '60px', lineHeight: '70px'}}>
            <span style={{color: "#701D15", fontFamily: "Poppins", fontWeight: "700"}}>
              Upload Cases
            </span>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              <wbr/> To Your Heart's Content
            </span>
          </div>
          <div style={{textAlign: 'left'}}>
            <span style={{
              textAlign: "left",
              color: '#4A4A4A',
              fontSize: 18,
              fontFamily: 'Lato',
              fontWeight: '400',
              textTransform: 'none',
            }}> 
            So, you love our Camera — but what will you do with hundreds of hours of operative footage?
            Orchid cloud has you covered with <b>unlimited HIPAA-Compliant cloud storage </b>
            for your videos, where data safety and reliability are top priorities.             </span>
          </div>
        </div>
        <div className="content-container">
          <div style={{position: 'relative', width: '100%', height: '420px'}}>
          <BackgroundShapes src="/images/store/Landing/upload_bkg.svg" alt="background shapes"/>
          <img src="/images/store/Landing/Sample_Case_1.png" alt="upload" 
            style={{height: '110px', position: 'absolute', top: 0, left: '50%', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/Sample_Case_2.png" alt="upload" 
            style={{height: '123px', position: 'absolute', top: 110, left: '60%', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/Sample_Case_3.png" alt="upload" 
            style={{height: '123px', position: 'absolute', top: 225, left: '40%', transform: 'translate(-50%, 0%)'}}/>
          <img src="/images/store/Landing/Sample_Case_4.png" alt="upload" 
            style={{height: '110px', position: 'absolute', top: 340, left: '50%', transform: 'translate(-50%, 0%)'}}/>
          </div>
        </div>
      </Panel>
      <Panel>
        <div className="content-container">
          <BackgroundShapes src="/images/store/Landing/enrich_bkg.svg" alt="background shapes"/>
          <div style={{position: 'relative', width: '100%', minWidth: '450px', height: '300px'}}>
          <img src="/images/store/Landing/segment_image.png" alt="Edit videos of Surgery" 
            style={{height: '300px', width: 'auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
          <img src="/images/store/Landing/segment1.png" alt="upload" 
            style={{height: '60px', position: 'absolute', top: 10, left: 'calc(300px)'}}/>
          <img src="/images/store/Landing/segment2.png" alt="upload" 
            style={{height: '60px', position: 'absolute', top: 84, left: 'calc(260px + 12%)'}}/>
          <img src="/images/store/Landing/segment3.png" alt="upload" 
            style={{height: '60px', position: 'absolute', top: 158, left: 'calc(220px + 24%)'}}/>
          <img src="/images/store/Landing/addnew.png" alt="upload" 
            style={{height: '45px', position: 'absolute', top: 242, left: 'calc(240px + 24%)'}}/>
          </div>
        </div>
        <div className="text-container">
          <div style={{fontSize: '68px', lineHeight: '70px'}}>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              Easily
            </span>
            <span style={{color: "#6A4711", fontFamily: "Poppins", fontWeight: "700"}}>
              <wbr/> Enrich
            </span>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              <wbr/> Your Cases
            </span>
          </div>
          <div style={{textAlign: 'left'}}>
          <span style={{
            textAlign: "left",
            color: '#4A4A4A',
            fontSize: 18,
            fontFamily: 'Lato',
            fontWeight: '400',
            textTransform: 'none',
          }}> 
            Using Orchid’s Case segmentation tool, break down your video into <b>clickable chapters</b> and make it easy to navigate, without the hassle of editing. Apply Case templates with one click, and compare steps across similar procedures.
          </span>
          </div>
        </div>
      </Panel>
      <Panel>
        <div className="text-container">
          <div style={{fontSize: '60px', lineHeight: '70px'}}>
            <span style={{color: "#0C3C83", fontFamily: "Poppins", fontWeight: "700"}}>
              Share
            </span>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              <wbr/> With Your Peers
            </span>
          </div>
          <div style={{textAlign: 'left'}}>
            <span style={{
              textAlign: "left",
              color: '#4A4A4A',
              fontSize: 18,
              fontFamily: 'Lato',
              fontWeight: '400',
              textTransform: 'none',
            }}> 
              Say Goodbye to transferring enormous video files. Once your cases are on orchid, sharing with peers and colleagues couldn’t be easier. Whether you are part of an orchid group, or just want to DM a case to a friend, Orchid makes collaboration secure and simple.
            </span>
          </div>
        </div>
        <div className="content-container">
          <BackgroundShapes src="/images/store/Landing/Share_bkg.svg" alt="background shapes" />
          <div style={{position: 'relative', width: '100%', minWidth: '450px', height: '400px'}}>
          <img src="/images/store/Landing/message_image.png" alt="Share videos of surgery" 
            style={{height: '250px', position: 'absolute', top: 0, left: '55%', transform: 'translate(-50%, 0%)'}} />
          <img src="/images/store/Landing/message2.png" alt="Exchange surgical knowledge with your peers" 
            style={{height: '100px', position: 'absolute', top: '150px', left: '50%', transform: 'translate(-50%, 50%)'}}/>
          <img src="/images/store/Landing/message3.png" alt="Institutional memory for surgical residencies" 
            style={{height: '100px', position: 'absolute', top: '205px', right: '0', transform: 'translate(10%, 50%)'}} />
          <img src="/images/store/Landing/message1.png" alt="Digital education for surgical residents" 
            style={{height: '100px', position: 'absolute', top: '255px', left: '0', transform: 'translate(-10%, 50%)'}} />
          </div>
        </div>
      </Panel>
      <PricingPanel>
        <div className="background-left">
          <img src="/images/store/Landing/big_triangles_left.svg" alt="background shapes" />
        </div>
        <div className="background-right">
          <img src="/images/store/Landing/big_triangles_right.svg" alt="background shapes" />
        </div>
        <div className="call-to-action">
          <div style={{textAlign: 'center', fontSize: '68px', lineHeight: '70px', maxWidth:"50vw"}}>
            <span style={{color: "#303030", fontFamily: "Lato", fontWeight: "600"}}>
              Ready to Get Started?
            </span>
          </div>
          <Form 
            placeholder="Your Email"
            text="Book A Demo"
            buttonColor="#3D85BB"
            buttonTextColor="#ffffff"
            formName="LIST"
            formValue="WaitList"
            width="400px"
          />
          <div style={{fontSize: 18, fontFamily: 'Quicksand', fontWeight: '500'}}>
            <span style={{color: '#BFBFBF'}}>Already On Orchid Cloud? </span>
            <StyledLink to={'/login/'}> Sign In</StyledLink>
          </div>
        </div>
    </PricingPanel>
      <Footer/>
    </StyledLandingPage>
    </>
  );
};

export default LandingPage;

export const StyledLandingPage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  @media only screen and (max-width: 900px) {
    display:none;
  }

`;

export const MobileStyledLandingPage = styled.div`
  @media only screen and (min-width: 900px) {
    display: none;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
`;

export const HeaderBackground = styled.div`
  position: absolute;
  width: 720px;
  height: auto;
  z-index: -10;
  right: 0;
  top: 0;

  img {
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const BackgroundShapes = styled.img`
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  width: 135%;
  height: 135%;
  transform: translate(-50%, -50%);
  object-fit: contain;
`;

export const Panel = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */
  width: 100%;
  height: 100%;
  padding: 0 100px;
  gap: 100px;
  margin-top: 20px;
  margin-bottom: 100px;
  max-width: 1600px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    padding: 0 10px;
    gap: 40px;
    margin-bottom: 40px;
  }

  /* border: 1px solid black; */
  .text-container {
    flex: 1 1 0;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    white-space: wrap;
    word-wrap: break-word;
    text-align: left;
    width: 100%;
    height: 100%;
    gap: 20px;
    max-width: 800px;
    flex-wrap: nowrap;
    /* border: 1px solid red; */
  }

  .content-container {
    position: relative;
    flex: 1 1 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 0;
    /* border: 1px solid blue; */
  }

`;

const PricingPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 500px;
  gap: 100px;
  background-color: #F5F5F5;

  .background-left{
    position: absolute;
    left: -50px;
    top: 0;
    img {
      height: 100%;
      width: 100%;
    }

    @media only screen and (max-width: 900px) {
      opacity: .2;
    }
  }

  .background-right{
    position: absolute;
    right: -50px;
    bottom: 0;
    img {
      height: 100%;
      width: 100%;
    }

    @media only screen and (max-width: 900px) {
      opacity: .2;
    }
  }

  .call-to-action {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 900px) {
      text-align: center;
    }
  }

`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #444A9F !important;
  font-size: 18px;
  font-weight: 600;

`;

