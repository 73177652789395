import { styled } from "styled-components";
import React, { useState, useEffect, useRef } from 'react';
import { propTypes } from "react-bootstrap/esm/Image.js";
import { Button } from "react-bootstrap";


const ExportDownload = ({exportData, handleExport, attachment}) => {

    const handleExportClick = (e) => {
        e.currentTarget.disabled=true;
        e.currentTarget.innerText = "Export Requested...";
        handleExport(e);
    }

    const getExportTimeEstimate = (attachment, startTime) => {
        let hours = 24;
        if (attachment && attachment.user_metadata && attachment.user_metadata.duration) {
            hours = attachment.user_metadata.duration / 60 / 60;
        }

        if (startTime) {
            let now = new Date();
            startTime = new Date(startTime + "Z");
            let diff = now - startTime;
            let diffHours = diff / 1000 / 60 / 60;
            hours = hours - diffHours;
        }

        if (hours <= 1) {
            return "less than an hour";
        } else {
            return Math.ceil(hours).toString() + " hours";
        }

    }

    if (!exportData) {
        return (
            <p>
            To export this case as an MP4 video, click the button below. We'll
            send you an email when the export is ready to download, estimated
            at <b>{getExportTimeEstimate(attachment, null)}</b>. <br /><br />
            <Button onClick={handleExportClick}>Export</Button>
          </p>
        );
    }

    if (exportData.status === 'pending' || exportData.status === 'processing') {
        return (
            <p>
                Your export has been requested and is currently {exportData.status}.
                We'll send you an email when it's ready to download, currently estimated
                at <b>{getExportTimeEstimate(attachment, exportData.created_on)}</b>.<br />
            </p>
        )
    }

    if (exportData.status === 'ready' && exportData.download_link) {
        return (
            <p>
                Your export is ready to download. Click the link below to download the file.<br /><br />
                <Button href={exportData.download_link} target="_blank" download variant="outline-info">
                    <img src="/images/icons/download.svg" width="16px" />&nbsp;
                    Download Export
                </Button>
            </p>
        )
    }
}

export default ExportDownload;

const DownloadLink = styled.a`
    font-color: #007bff; !important;
    text-decoration: underline; !important;
`;
