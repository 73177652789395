import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import DropFiles from "./DropFiles.js";
import Search from "./Search.js";
import * as cases from '../services/api/cases.js';
import * as attachments from '../services/api/attachments.js';
import { getVideoFrame, getVideoMetadata, sortVideoFilesByName} from "../services/util/video.js";
import { uploadFiles } from '../services/redux/fileUploadSlice';
import { addNotification } from '../services/redux/notificationsSlice';
import mixpanel from 'mixpanel-browser';

const FileItem  = ({filename, size, duration, thumbnail, index, handleChangeFileOrder, handleRemoveFile}) => {
    return (
        <FileItemContainer>
            <FileInfoGroup>
                <FileItemThumbnail src={thumbnail} />
                <span style={{width: '200px'}}>{filename}</span>
                <span>{duration ? Math.round(duration/60) : 0} m</span>
                <span>{Math.round(size/1000000)} MB</span>
            </FileInfoGroup>
            <FileButtonGroup>
                <ImgButton
                    src="/images/icons/expand-contract.svg"
                    style={{rotate: '180deg'}}
                    onClick={() => {handleChangeFileOrder(index, 'up')}}
                    />
                <ImgButton
                    src="/images/icons/expand-contract.svg"
                    onClick={() => {handleChangeFileOrder(index, 'down')}}
                    />
                <ImgButton 
                    src="/images/icons/x-symbol.svg"
                    onClick={() => {handleRemoveFile(index)}}
                    />
            </FileButtonGroup>
        </FileItemContainer>
    )
}

const FileItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    gap: 10px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 7px 10px; 
    box-shadow: 0 0 10px rgba(0,0,0,.1);
`;

const FileItemThumbnail = styled.img`
    width: auto;
    height: 30px;
    border-radius: 7px;
`;

const FileInfoGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    min-width: 70%;
    align-items: center;
`;

const FileButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;


const UploadModal = ({ show, onHide }) => {

    const dispatch = useDispatch();

    const [fileList, setFileList] = useState([]);
    const [fileSizeList, setFileSizeList] = useState([]);
    const [fileLengthList, setFileLengthList] = useState([]);
    const [caseName, setCaseName] = useState('');
    const [caseDescription, setCaseDescription] = useState('');
    const [thumbnails, setThumbnails] = useState([]);
    const [noTitleError, setNoTitleError] = useState(false);

    const handleClearFiles = () => {
        setFileList([]);
        setFileSizeList([]);
        setFileLengthList([]);
        setCaseName('');
        setCaseDescription('');
        setThumbnails([]);
    }

    const handleAddFiles = (files) => {

        //remove duplicates
        //keep files that aren't mp4s
        //case insensitive
        let newFiles = [];
        for (let i = 0; i < files.length; i++) {
            let duplicate = false;
            let mp4 = false;

            if (files[i].name.toLowerCase().endsWith('.mp4')) {
                mp4 = true;
            }
            for (let j = 0; j < fileList.length; j++) {
                if (files[i].name === fileList[j].name) {
                    duplicate = true;
                    break;
                }
            }
            if (!duplicate && mp4) {
                newFiles.push(files[i]);
            }


        }

        let combinedFiles = [...fileList, ...newFiles];
        combinedFiles = sortVideoFilesByName(combinedFiles);

        //update properties
        setFileList(combinedFiles);
        setFileSizeList(combinedFiles.map((file) => file.size));
        Promise.all(combinedFiles.map((file) => getVideoMetadata(file)))
        .then((metadataList) => {
            setFileLengthList(metadataList.map((metadata) => metadata.duration));
        });
        Promise.all(combinedFiles.map((file) => getVideoFrame(file, 0)))
        .then((newThumbnails) => {
            setThumbnails(newThumbnails);
        });
    }

    const handleRemoveFile = (ind) => {
        let newFileList = [...fileList];
        let newFileSizeList = [...fileSizeList];
        let newFileLengthList = [...fileLengthList];
        let newThumbnails = [...thumbnails];
        newFileList.splice(ind, 1);
        newFileSizeList.splice(ind, 1);
        newFileLengthList.splice(ind, 1);
        newThumbnails.splice(ind, 1);
        setFileList(newFileList);
        setFileSizeList(newFileSizeList);
        setFileLengthList(newFileLengthList);
        setThumbnails(newThumbnails);
    }

    const handleChangeFileOrder = (ind, direction) => {
        let newFileList = [...fileList];
        let newFileSizeList = [...fileSizeList];
        let newFileLengthList = [...fileLengthList];
        let newThumbnails = [...thumbnails];
        if (direction === 'up') {
            if (ind > 0) {
                [newFileList[ind-1], newFileList[ind]] = [newFileList[ind], newFileList[ind-1]];
                [newFileSizeList[ind-1], newFileSizeList[ind]] = [newFileSizeList[ind], newFileSizeList[ind-1]];
                [newFileLengthList[ind-1], newFileLengthList[ind]] = [newFileLengthList[ind], newFileLengthList[ind-1]];
                [newThumbnails[ind-1], newThumbnails[ind]] = [newThumbnails[ind], newThumbnails[ind-1]];
            }
        } else if (direction === 'down') {
            if (ind < newFileList.length - 1) {
                [newFileList[ind+1], newFileList[ind]] = [newFileList[ind], newFileList[ind+1]];
                [newFileSizeList[ind+1], newFileSizeList[ind]] = [newFileSizeList[ind], newFileSizeList[ind+1]];
                [newFileLengthList[ind+1], newFileLengthList[ind]] = [newFileLengthList[ind], newFileLengthList[ind+1]];
                [newThumbnails[ind+1], newThumbnails[ind]] = [newThumbnails[ind], newThumbnails[ind+1]];
            }
        }
        setFileList(newFileList);
        setFileSizeList(newFileSizeList);
        setFileLengthList(newFileLengthList);
        setThumbnails(newThumbnails);
    }

    const handleUpload = () => {
        if (fileList.length > 0 && caseName.length > 0)
        {      
            uploadVideo(fileList, caseName, caseDescription, fileLengthList.reduce((acc, fileLength) => acc + fileLength, 0));
            handleClearFiles();
            onHide();
            mixpanel.track('Clicked Upload Video', {'Source': 'Upload Dialogue'})
        } else {
            setNoTitleError(true);
        }
    }

    const uploadVideo = async (files, caseName, caseDescription, videoLength) => {
        let attachment_id = null;
        // let arrayBuffers = await Promise.all(files.map((file) => file.arrayBuffer()));
        // let hashes = await Promise.all(arrayBuffers.map((arrayBuffer) => window.crypto.subtle.digest('SHA-256', arrayBuffer)));
        // hashes = hashes.map((hash) => Array.from(new Uint8Array(hash)).map(b => b.toString(16).padStart(2, '0')).join(''));
        let hashes = files.map(() => '1234567890abcdef1234567890abcdef1234567890abcdef1234567890abcdef');
        cases.createCase(
            {
                'case_name': caseName, 
                'description': caseDescription,
            }
        )
        .then(data => data['id'])
        .then(case_id => attachments.createAttachment(
            {
                'case_id': case_id,
                'user_metadata': { 
                    'duration': videoLength
                }
            }
        ))
        .then(data => {
            attachment_id = data['id']
            return attachment_id
        })
        .then(attachment_id => {
            fetch(thumbnails[0]).then(res => res.blob()).then((blob) => {
                attachments.uploadThumbnail(attachment_id, blob);
            })
            console.log('dispatching uploadFiles')
            let totalSize = files.reduce((sum, file) => sum + file.size, 0);
            dispatch(addNotification({id: attachment_id, title: 'Your upload has started.', variant: 'info', message: 'For best results, please keep this tab open and ensure your device does not go to sleep.'}));
            dispatch(uploadFiles({ attachment_id, caseName, caseDescription, files, checksums: hashes, totalSize, videoLength}));
        })
    };

    return (
        <Modal centered show={show} onHide={onHide} size='lg' >
            <Modal.Header closeButton>
                <Modal.Title>Upload Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DropZoneContainer>
                    Click below or drag-and-drop to choose video files (mp4) to upload:
                    <DropFiles handleChange={handleAddFiles}/>
                    <div style={{flexDirection: "column", justifyContent: "space-between"}}>
                    {fileList.length} file(s), &nbsp;
                    {Math.round(fileLengthList.reduce((acc, fileLength) => acc + fileLength, 0)/60)} minutes, &nbsp;
                    {Math.round(fileSizeList.reduce((acc, fileSize) => acc + fileSize, 0)/100000000)/10} GB.
                    Multiple files will be combined into a single video.
                    <Button variant='link' onClick={handleClearFiles}>
                        Clear Files
                    </Button>
                    </div>
                </DropZoneContainer>
                <FileListArea>
                    {fileList.map((file, ind) => {
                        return <FileItem 
                                key={ind}
                                filename={file.name}
                                size={file.size}
                                duration={fileLengthList[ind]}
                                thumbnail={thumbnails[ind]}
                                index={ind}
                                handleChangeFileOrder={handleChangeFileOrder}
                                handleRemoveFile={handleRemoveFile}
                            />
                    })}
                </FileListArea>
                <LabelArea>
                    {/* <TagArea>
                        <Search
                            onSearch={() => {return new Promise((resolve, reject) => {resolve([])})}}
                            placeholder="Case Type"
                        />
                        <Search
                            onSearch={() => {return new Promise((resolve, reject) => {resolve([])})}}
                            placeholder="Approach"
                        />
                        <Search
                            onSearch={() => {return new Promise((resolve, reject) => {resolve([])})}}
                            placeholder="Laterality"
                            style={{width: '80px'}}
                        />
                    </TagArea> */}
                    <br/>
                    <Form.Label>Case Name and Description</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter a short, descriptive title (required)"
                        value={caseName}
                        isInvalid={noTitleError}
                        onChange={(e) => {setCaseName(e.target.value); setNoTitleError(false)}}
                    />
                    <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="Case Notes"
                        value={caseDescription}
                        onChange={(e) => setCaseDescription(e.target.value)}
                    />
                </LabelArea>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => {handleUpload()}}>
                    Upload
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const DropZoneContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
`;

const LabelArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;
`;

const TagArea = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 10px;
`;

const FileListArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 160px;
    gap: 5px;
    overflow-y: scroll;
    padding: 10px;
`;

const ImgButton = styled.img`
    height: 24px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;

export default UploadModal;