import Modal from 'react-bootstrap/Modal';
import { useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { getGroupMembers, getGroups } from "../services/api/groups.js"
import { getShares, createShare, deleteShare, updateAttachment, getAttachment } from '../services/api/attachments';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SymbolBubble from './SymbolBubble.js';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import mixpanel from 'mixpanel-browser';

function CaseShareModal(props) {
    const [groups, setGroups] = useState([]);
    const [isSharedWithGroup, setIsSharedWithGroup] = useState([]);
    const [confirmationMessage, setConfirmationMessage] = useState([]);
    const [shares, setShares] = useState([]);
    const [videoContainsPHI, setVideoContainsPHI] = useState(null);
    const [videoHasConsent, setVideoHasConsent] = useState(null);
    const [videoCanBeShared, setVideoCanBeShared] = useState(null);
    const [activeTab, setActiveTab] = useState('groups')
    const mounted = useRef(true);

    useEffect(() => {
        // console.log('getting groups for user ' + props.user.id)
        getAttachment(props.attachment.id)
        .then(attachment => {
            // console.log('got attachment', attachment)
            setVideoContainsPHI(attachment.contains_phi);
            setVideoHasConsent(attachment.with_patient_consent);
            if (attachment.contains_phi === null || attachment.with_patient_consent === null)
                setActiveTab('phi-info')
        })
        .then(() => {
            getGroups()
            .then(group_results => {
                // console.log('got groups for user ' + props.user.id, group_results)
                setGroups(group_results);
                setConfirmationMessage(Array(group_results.length).fill(''));
                setSharingEligibility();
                // console.log('getting shares for attachment ', props.attachment.id)
                getShares(props.attachment.id)
                .then(items => {
                    // console.log('got shares for attachment ', items)
                    setShares(items);
                    setIsSharedWithGroup(() => groups.map(group => {
                        let isShared = items.some(item => item.group_id === group.id);
                        // console.log('shared', group.name, isShared)
                        return isShared;
                    }));
                })
            })
        })
    }, [props.show])

    //useEffect to monitor sharing permissions
    useEffect(() => {
       setSharingEligibility();
       setSharedStatus(props.attachment.id);
    }, [videoContainsPHI, videoHasConsent])

    function setSharingEligibility() {
        let temp_videoCanBeShared = Array(groups.length).fill(null);
        groups.forEach((group, idx) => {
            // console.log("Checking sharing eligibility for group " + group.name)
            // console.log(checkSharingEligibility(group))
            temp_videoCanBeShared[idx] = checkSharingEligibility(group);
        })
        setVideoCanBeShared(temp_videoCanBeShared);
    }

    function setSharedStatus (attachment_id) {
        getShares(attachment_id)
            .then(items => {
                // console.log('got shares for attachment ', items)
                setShares(items);
                setIsSharedWithGroup(() => groups.map(group => {
                    let isShared = items.some(item => item.group_id === group.id);
                    // console.log('shared', group.name, isShared)
                    return isShared;
                }));
            })
    }

    function checkSharingEligibility (group) {
        if (group.allow_phi === false) {
            //no phi is allowed, ever
            if (videoContainsPHI === false && videoHasConsent != null )
                return true;
            else
                return false;
        }
        else if (group.allow_phi === true && group.require_patient_consent === true) {
            //phi is allowed, but only with consent
            if (videoContainsPHI === false && videoHasConsent != null)
                //no phi, no problem
                return true;
            else if (videoContainsPHI === true && videoHasConsent === true)
                //phi, but with consent
                return true;
            else
                //phi, but no consent
                return false;
        }
        else if (group.allow_phi === true && group.require_patient_consent === false) {
            //this is a care team group, so phi is allowed without consent
            if (videoContainsPHI != null && videoHasConsent != null)
                return true;
            else
                return false;
        }
        else
            return false;
    }

    const onPHIChange = (value) => {
        // console.log("Contains PHI: ", value)
        updateAttachment(props.attachment.id, { "contains_phi" : value }).then((response) => {
            if (response)
                setVideoContainsPHI(response.contains_phi)
            mixpanel.track('Updated Video PHI', 
                {
                    'Source': 'Viewer Page', 
                    'Attachment ID': props.attachment.id,
                    'Value': value
                }
            )
        })
    }

    const onConsentChange = (value) => {
        // console.log("Has consent: ", value)
        updateAttachment(props.attachment.id, { "with_patient_consent" : value }).then((response) => {
            if (response)
                setVideoHasConsent(response.with_patient_consent)
            mixpanel.track('Updated Video Consent', 
                {
                    'Source': 'Viewer Page', 
                    'Attachment ID': props.attachment.id,
                    'Value': value
                }
            )
        })
    }

    function renderInfoBubble (group) {
        if (group.allow_phi === false) {
            //no phi is allowed, ever
            return <SymbolBubble 
                        text="This group does not allow PHI." 
                        variant="info"
                    />
        }
        else if (group.allow_phi === true && group.require_patient_consent === true) {
            //phi is allowed, but only with consent
            return <SymbolBubble 
                        text="This group allows PHI with patient consent." 
                        variant="info"
                    />
        }
        else if (group.allow_phi === true && group.require_patient_consent === false) {
            //this is a care team group, so phi is allowed without consent
            return <SymbolBubble 
                        text="This group is a care team." 
                        variant="info"
                    />
        }
    }
    
    return (
    <Modal
        {...props}
        size="lg"
        centered
    >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Share "{props.attachment.case.case_name}" with your groups
        </Modal.Title>
        </Modal.Header>
        <Tabs 
            id="share-tabs"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mb-3"
        >
            <Tab eventKey="phi-info" title="PHI Disclosures">
                <Modal.Body>
                    To enable sharing features for this video, you must complete the 
                    following attestations. 
                    <SymbolBubble text="If you update these attestations after sharing, your video will be 
                    removed from any groups or messages whose PHI policies are not compatible with the new settings." variant='warning'></SymbolBubble>
                     <br/><br/>
                <Form>
                <Container>
                    <Row>
                    <Col xs={8}>
                    <span>Does this video or its title/description contain PHI?</span>
                    </Col>
                    <Col xs={2}>
                    <Form.Check
                        inline
                        checked={videoContainsPHI === true}
                        onChange={() => {onPHIChange(true)}}
                        type='radio'
                        name='phi-radio'
                        label={`Yes`}
                        id={`phi-radio-yes`}
                    />
                    </Col>
                    <Col xs={2}>
                    <Form.Check
                        inline
                        checked={videoContainsPHI === false}
                        onChange={(event) => {onPHIChange(false)}}
                        type='radio'
                        name='phi-radio'
                        label={`No`}
                        id={`phi-radio-no`}
                    />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={8}>
                    <span>Have you obtained the patient's written consent to disclose PHI?</span>
                    </Col>
                    <Col xs={2}>
                    <Form.Check
                        inline
                        checked={videoHasConsent === true}
                        onChange={() => onConsentChange(true)}
                        type='radio'
                        name='consent-radio'
                        label={`Yes`}
                        id={`consent-radio-yes`}
                    />
                    </Col>
                    <Col xs={2}>
                    <Form.Check
                        inline
                        checked={videoHasConsent === false}
                        onChange={() => onConsentChange(false)}
                        type='radio'
                        name='consent-radio'
                        label={`No`}
                        id={`consent-radio-no`}
                    />
                    </Col>
                </Row>
                </Container>
            </Form>
            <br/>
            
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {setActiveTab('groups')}}>Next</Button>
            </Modal.Footer>
            </Tab>
            {!props.no_share &&
            <Tab eventKey="groups" title="Groups">
                <Modal.Body>
                    <Form>
                        <Container>
                            <Row>
                                <Col xs={5}>
                                    Group
                                </Col>
                                <Col xs={1}>
                                    Share?
                                </Col>
                                <Col xs={6}>
                                </Col>  
                            </Row>
                            <Row><br/></Row>
                            {groups.map((group, idx) => {
                                let isShared = isSharedWithGroup[idx];
                                let isEligible = videoCanBeShared[idx];
                                return (
                                    <Row>
                                        <Col xs={5}>
                                            {group.name}  {renderInfoBubble(group)}
                                        </Col>
                                        <Col xs={1}>
                                            <Form.Check
                                                type="switch"
                                                key={group.id}
                                                checked={isShared}
                                                disabled={!isEligible}
                                                onChange={() => {
                                                    if (!isShared) {
                                                        createShare(props.attachment.id, { "group_id" : group.id }).then(() => {
                                                            setConfirmationMessage(() =>
                                                            [
                                                                ...confirmationMessage.slice(0, idx), 
                                                                'Your video has been shared with the group.', 
                                                                ...confirmationMessage.slice(idx + 1)
                                                            ]
                                                            )
                                                            mixpanel.track('Shared Video', 
                                                                {
                                                                    'Source': 'Viewer Page', 
                                                                    'Group ID': group.id, 
                                                                    'Group Name': group.name,
                                                                    'Attachment ID': props.attachment.id
                                                                }
                                                            )
                                                        }
                                                        )
                                                    } else {
                                                        deleteShare(props.attachment.id, group.id).then(() => {
                                                            setConfirmationMessage(() =>
                                                            [
                                                                ...confirmationMessage.slice(0, idx), 
                                                                'Your video has been removed from the group.', 
                                                                ...confirmationMessage.slice(idx + 1)
                                                            ]
                                                            )
                                                            mixpanel.track('Unshared Video', 
                                                                {
                                                                    'Source': 'Viewer Page', 
                                                                    'Group ID': group.id, 
                                                                    'Group Name': group.name,
                                                                    'Attachment ID': props.attachment.id
                                                                }
                                                            )
                                                        }
                                                        )
                                                    }
                                                    let temp_isSharedWithGroup = [...isSharedWithGroup];
                                                    temp_isSharedWithGroup[idx] = !isSharedWithGroup[idx];
                                                    setIsSharedWithGroup(temp_isSharedWithGroup);
                                                }}
                                            >
                                            </Form.Check>
                                        </Col>
                                        <Col xs={6}>
                                            {confirmationMessage[idx]}
                                        </Col>
                                    </Row>
                                );
                            })}
                            <br/>
                            <div> Want to share this video with another Orchid user? Take me to <Link to='/messages/' style={{fontWeight: 'bold', color: 'blue'}}>Messages</Link>!</div>
                        </Container>
                    </Form>
                    </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Done</Button>
                </Modal.Footer>
        </Tab>
        }
        </Tabs>
    </Modal>
    );
}

    export default CaseShareModal