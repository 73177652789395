import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const removeClip = createAsyncThunk(
    'timeline/removeClip',
    async (clipNumber, thunkAPI) => {
        const state = thunkAPI.getState();
        const { current, versions } = state.timeline;
        const newVersions = versions.slice(0, current + 1);
        newVersions.push({
            clips: versions[current].clips.slice(0, clipNumber).concat(versions[current].clips.slice(clipNumber + 1)),
        });
        return newVersions;
    }
);

const timelineSlice = createSlice({
    name: 'timeline',
    initialState: {
        versions: [
            {
                clips: [
                    {
                        startTime: 0,
                        active: true,
                        muted: false,
                        modifiedStartTime: 0,
                    },
                ],
            },
        ],
        current: 0,
        previous: null,
    },

    reducers: {
        addVersion: (state, action) => {
            state.versions.push(action.payload);
            state.previous = state.current;
            state.current = state.versions.length - 1;
        },
        removeVersion: (state, action) => {
            const { versionNumber } = action.payload;
            state.versions = state.versions.slice(0, versionNumber).concat(state.versions.slice(versionNumber + 1));
        },
        setCurrent: (state, action) => {
            const { versionNumber } = action.payload;
            console.log('attempting to set version number', versionNumber)
            if (versionNumber < 0 || versionNumber >= state.versions.length) {
                return;
            }
            state.previous = state.current;
            state.current = versionNumber;
            console.log('current version number', state.current)
        },
        incrementCurrent: (state) => {
            if (state.current < state.versions.length - 1) {
                state.previous = state.current;
                state.current++;
            }
        },
        decrementCurrent: (state) => {
            if (state.current > 0) {
                state.previous = state.current;
                state.current--;
            }
        },
        setClipMute: (state, action) => {
            const { clipNumber, isMuted } = action.payload;
            
            // trim versions beyond current
            state.versions = state.versions.slice(0, state.current + 1);
            
            //add new version
            state.versions.push(
                state.versions[state.current].clips[clipNumber].muted = isMuted
            );

            //advance the current version
            state.previous = state.current;
            state.current++;
        },
        setClipActive: (state, action) => {
            const { clipNumber, isActive } = action.payload;
            
            // trim versions beyond current
            state.versions = state.versions.slice(0, state.current + 1 );
            
            //add new version
            state.versions = [...state.versions, { clips: JSON.parse(JSON.stringify(state.versions[state.current].clips)) }];

            //advance the current version
            state.previous = state.current;
            state.current++;

            //set the new active state
            state.versions[state.current].clips.splice(clipNumber, 1, { 
                startTime: state.versions[state.current].clips[clipNumber].startTime, 
                active: isActive, 
                muted: state.versions[state.current].clips[clipNumber].muted, 
                modifiedStartTime: state.versions[state.current].clips[clipNumber].modifiedStartTime
            });

            //ensure the correct modified start time for all subsequent clips by adding or subtracting the duration of the clip, depending on isActive
            //but only if not the last clip
            if (clipNumber !== state.versions[state.current].clips.length - 1) {
                let clipDuration = state.versions[state.current].clips[clipNumber + 1].startTime - state.versions[state.current].clips[clipNumber].startTime;
                console.log('clip duration for removal', clipDuration)
                for (let i = clipNumber + 1; i < state.versions[state.current].clips.length; i++) {
                    state.versions[state.current].clips[i].modifiedStartTime = state.versions[state.current].clips[i].modifiedStartTime + (isActive ? clipDuration : -clipDuration)
                };
            }

            console.log('final versions after remove clip', state.versions)

        },
        addCut: (state, action) => {
            const { startTime } = action.payload;

            //determine where the new clip will go, depending on start time
            let clipNumber = 0;
            while (clipNumber < state.versions[state.current].clips.length && state.versions[state.current].clips[clipNumber].startTime < startTime) {
                clipNumber++;
            }
            
            //create a new clip, preserving the active and muted states of the previous clip
            // const newClip = {
            //     startTime: startTime,
            //     active: state.versions[state.current].clips[clipNumber - 1].active,
            //     muted: state.versions[state.current].clips[clipNumber - 1].muted,
            //     modifiedStartTime: state.versions[state.current].clips[clipNumber - 1].modifiedStartTime + (startTime-state.versions[state.current].clips[clipNumber - 1].startTime)
            // };

            const newClip = JSON.parse(JSON.stringify(state.versions[state.current].clips[clipNumber - 1]));
            newClip.startTime = startTime;
            newClip.modifiedStartTime = newClip.modifiedStartTime + (startTime - state.versions[state.current].clips[clipNumber - 1].startTime);

            // trim versions beyond current
            state.versions = state.versions.slice(0, state.current + 1);

            state.versions = [...state.versions, { clips: [...state.versions[state.current].clips] }];

            state.previous = state.current;
            state.current++;

            state.versions[state.current].clips.splice(clipNumber, 0, newClip);

            console.log('final versions after addcut', state.versions)
        },
    }
});

export const { addVersion, removeVersion, setCurrent, incrementCurrent, decrementCurrent, setClipMute, setClipActive, addCut } = timelineSlice.actions;

export default timelineSlice.reducer;