import { get, post, patch, del, paramsToString } from './common.js';
import { patch as patch_no_error_handling } from './common_no_err_handling.js';

//get all attachments
export function getAttachments(params) {
    return get(`/attachments/${paramsToString(params)}`);
}

//get all attachments by case id
export function getAttachmentsByCase(case_id) {
    return get(`/attachments/?case_id=${case_id}/`);
}

//get attachment by id
export function getAttachment(id) {
    return get(`/attachments/${id}/`);
}

//create attachment
export function createAttachment(data) {
    return post('/attachments/', data);
}

//delete attachment by id
export function deleteAttachment(id) {
    return del(`/attachments/${id}/`);
}

//update attachment by id
export function updateAttachment(id, data) {
    return patch(`/attachments/${id}/`, data);
}

//updateAttachmentStatus by id
export function updateAttachmentStatus(id, data) {
    return patch(`/attachments/${id}/status/`, data);
}

//create download link for attachment by id
export function generateDownloadLink(id) {
    return get(`/attachments/${id}/download_link/`);
}

//create dowload link for hls manifest
export function generateManifestLink(id) {
    return get(`/attachments/${id}/manifest/`);
}

//create upload link for attachment by id
export function generateUploadLink(id, params) {
    return get(`/attachments/${id}/upload_link/${paramsToString(params)}`);
}

//upload thumbnail for attachment by id
export function uploadThumbnail(id, data) {
    return post(`/attachments/${id}/thumbnail/`, data, 'api', 'image/png');
}

//list all comments by attachment id
export function getComments(attachment_id, page=1) {
    return get(`/attachments/${attachment_id}/comments/?page=${page}`);
}

export function getTopLevelComments(attachment_id) {
    return get(`/attachments/${attachment_id}/comments/?reply_to_id=null`);
}

export function getTopLevelCommentsByShare(attachment_id, share_id, page=1) {
    return get(`/attachments/${attachment_id}/comments/?share_id=${share_id}&page=${page}&reply_to_id=null`);
}

export function getCommentsByReplyTo(attachment_id, reply_to_id) {
    return get(`/attachments/${attachment_id}/comments/?reply_to_id=${reply_to_id}`);
}

//create a new comment by attachment id
export function createComment(attachment_id, data) {
    return post(`/attachments/${attachment_id}/comments/`, data);
}

//get comment by id
export function getComment(attachment_id, comment_id) {
    return get(`/attachments/${attachment_id}/comments/${comment_id}/`);
}

//delete comment by id
export function deleteComment(attachment_id, comment_id) {
    return del(`/attachments/${attachment_id}/comments/${comment_id}/`);
}

//update comment by id
export function updateComment(attachment_id, comment_id, data) {
    return patch(`/attachments/${attachment_id}/comments/${comment_id}/`, data);
}

//get all shares by attachment id
export function getShares(attachment_id) {
    return get(`/attachments/${attachment_id}/group_shares/`);
}

//create a new share by attachment id
export function createShare(attachment_id, data) {
    return post(`/attachments/${attachment_id}/group_shares/`, data);
}

//get share by id
export function getShare(attachment_id, share_id) {
    return get(`/attachments/${attachment_id}/group_shares/${share_id}/`);
}

//delete share by id
export function deleteShare(attachment_id, share_id) {
    return del(`/attachments/${attachment_id}/group_shares/${share_id}/`);
}

//create marker by attachment id
export function createMarker(attachment_id, data) {
    return post(`/markers/`, {'attachment_id': attachment_id,...data});
}

//upload marker thumbnail by marker id
export function uploadMarkerThumbnail(id, data) {
    return post(`/markers/${id}/thumbnail/`, data, 'api', 'image/png');
}

//delete marker by id
export function deleteMarker(id) {
    return del(`/markers/${id}/`);
}

//get multipart upload by attachment id
export function getMultipartUpload(attachment_id) {
    return get(`/attachments/${attachment_id}/upload/`);
}

//create multipart upload by attachment id
export function createMultipartUpload(attachment_id, segments) {
    return post(`/attachments/${attachment_id}/upload/`, {'segments': segments});
}

//patch multipart upload by attachment id
export function patchMultipartUpload(attachment_id, segment_ind, status, etags) {
    return patch_no_error_handling(`/attachments/${attachment_id}/upload/`, {'segment_ind': segment_ind, 'status': status, 'etags': etags});
}

//given an attachment, return the thumbnail url
//if the attachment has markers, return the first marker thumbnail
//otherwise, return the attachment thumbnail
export function getThumbnailUrl(attachment) {
    // if (attachment.markers.length > 0) {
    //     //find the first marker by marker position
    //     attachment.markers.sort((a, b) => a.position - b.position);
    //     return attachment.markers[0].thumbnail.download_link;
    // } else {
    //     return attachment.thumbnail.download_link;
    // }
    return attachment.thumbnail.download_link;
}