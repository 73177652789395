import { styled } from "styled-components";
import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './VideoPlayer.css';
import mixpanel from 'mixpanel-browser';
import useWindowSize from "../hooks/useWindowSize";
import { patchAttachmentView } from "../services/api/attachment_views";

const VjsComponent = videojs.getComponent('Component');

class VjsBridgeComponent extends VjsComponent {
    constructor(player, options) {
        super(player, options);
        this.mountReactComponent = this.mountReactComponent.bind(this);
        player.ready(() => {this.mountReactComponent();});
        const root = createRoot(this.el());
        this.on('dispose', () => root.unmount());
        this.controlText='Video Quality';
    }

    mountReactComponent() {
        const root = createRoot(this.el());
        root.render(<QualitySelector player={this.player()}/>);
    }
}

const QualitySelector = ({player}) => {
    const [currentQuality, setCurrentQuality] = React.useState('Auto');
    const [playlists, setPlaylists] = React.useState();
    const masterPlaylist = player.src()
    // console.log(masterPlaylist);

    //enum to match quality names to quality levels
    const qualityLevels = [
        {name: 'SD', height: 720},
        {name: 'HD', height: 1080},
    ];


    function getQualityLevelsFromMasterPlaylist() {
        fetch(masterPlaylist, {credentials: 'include'})
        .then(response => response.text())
        .then(data => {
            //parse the master m3u8 playlist
            var lines = data.split('\n');
            var playlist = [];
            var currentQuality = null;
            for (let line of lines) {
                if (line.startsWith('#EXT-X-STREAM-INF')) {
                    currentQuality = {};
                    currentQuality.url = masterPlaylist.split('attachment')[0] + lines[lines.indexOf(line)+1];
                    currentQuality.height = parseInt(line.split('RESOLUTION=')[1].split(',')[0].split('x')[1]);
                    currentQuality.name = qualityLevels.find((quality) => quality.height === currentQuality.height).name;
                    playlist.push(currentQuality);
                }
            }
            setPlaylists(playlist);
        })
    }
        

    React.useEffect(() => {
        getQualityLevelsFromMasterPlaylist();
    }, [player]);
        

    const handleQualityChange = (preferredQuality) => {
        //if the preferred quality is the same as the current quality, do nothing
        if (preferredQuality === currentQuality)
            return;
        var muted = player.muted();
        var current_time = player.currentTime();
        if (preferredQuality === 'Auto')
           player.src(masterPlaylist);
        else 
            player.src(playlists.find((playlist) => playlist.name === preferredQuality).url);
        player.currentTime(current_time);
        player.muted(muted);
        console.log('Quality changed to ' + preferredQuality);
        console.log('player muted', muted);
        setCurrentQuality(preferredQuality);
    }

    return (
        <QualitySelectorContainer>
            <QualitySelectorButton>
                <img src='/images/icons/gear_hd.svg' />
            </QualitySelectorButton>
            <QualitySelectorMenu>
                <QualitySelectorMenuItem 
                    onClick={() => {
                        handleQualityChange('Auto');
                    }}
                    $isActive={currentQuality === 'Auto'}
                >Auto</QualitySelectorMenuItem>
                {playlists && playlists.map((playlist, index) => {
                    return (
                        <QualitySelectorMenuItem 
                            key={index}
                            onClick={() => {
                                handleQualityChange(playlist.name);
                            }}
                            $isActive={currentQuality === playlist.name}
                        >{playlist.name}</QualitySelectorMenuItem>
                    )
                })}
            </QualitySelectorMenu>
        </QualitySelectorContainer>
    )
}

const QualitySelectorContainer = styled.div`
    position: relative;
    `;

const QualitySelectorButton = styled.div`
    position: relative;
    display: inline-block;
    width: 40px;
    text-align: center;
    height: auto;
    border-radius: 10%;
    color: white;
    font-size: 12px;
    line-height: 2.5;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    text-transform: uppercase;

    img {
        height:16px;
        width: auto;

    }
`;

const QualitySelectorMenu = styled.div`
    position: absolute;
    bottom: 30px;
    right: 0;
    width: 100px;
    background-color: #2B333FB3;
    color: white;
    font-size: 12px;
    line-height: 2.5;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    text-transform: uppercase;
    border-radius: 7px 0 0 0;
    overflow: hidden;
    display: none;

    ${QualitySelectorContainer}:hover & {
        display: block;
    }
`;

const QualitySelectorMenuItem = styled.div`

    background-color: ${props => props.isActive ? 'rgba(200,200,200,0.6)' : 'transparent'};
    &:hover {
        background-color: rgba(200,200,200,0.5);
    }
    border-bottom: 1px solid rgba(100,100,100,0.5);
`;

const VideoPlayer = (props) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const windowSize = useWindowSize();
    let { options, onReady, src, info } = props;

    const [playSymbol, setPlaySymbol] = React.useState('▶');
    const playFcn = React.useRef(() => playerRef.current.play());

    //timer to track time watched
    const watchReporter = React.useRef(null);
    const watchTimerClock = React.useRef(0);

    const watchTimerData = React.useRef({
        secondsWatched: 0,
        secondsWaiting: 0,
        secondsWatchedSinceLastReport: 0,
        secondsWaitingSinceLastReport: 0,
        timeWatchedStarted: null,
        timeWaitingStarted: null,
        watching: false,
        waiting: false,
    });

    //functions to start and stop the watch timer
    function  startWatchTimer() {
        // timeStarted.current = Date.now();
        // watching.current = true;
        const now = Date.now();
        watchTimerData.current.timeWatchedStarted = now;
        watchTimerData.current.watching = true;
    }
    function stopWatchTimer() {
        // timeWatched.current += (Date.now() - timeStarted.current)/1000;
        // watching.current = false;
        const now = Date.now();
        watchTimerData.current.secondsWatched += (now - watchTimerData.current.timeWatchedStarted)/1000;
        watchTimerData.current.secondsWatchedSinceLastReport += (now - watchTimerData.current.timeWatchedStarted)/1000;
        watchTimerData.current.watching = false;
    }

    //functions to start and stop the waiting timer
    function  startWaitingTimer() {
        // if (!waiting.current) {
        //     timeWaitingStarted.current = Date.now();
        //     waiting.current = true;
        // }
        const now = Date.now();
        if (!watchTimerData.current.waiting) {
            watchTimerData.current.timeWaitingStarted = now;
            watchTimerData.current.waiting = true;
        }
    }

    function stopWaitingTimer() {
        // if (waiting.current) {
        //     timeWaiting.current += (Date.now() - timeWaitingStarted.current)/1000;
        //     waiting.current = false;
        // }
        const now = Date.now();
        if (watchTimerData.current.waiting) {
            watchTimerData.current.secondsWaiting += (now - watchTimerData.current.timeWaitingStarted)/1000;
            watchTimerData.current.secondsWaitingSinceLastReport += (now - watchTimerData.current.timeWaitingStarted)/1000;
            watchTimerData.current.waiting = false;
        }
    }

    //function to send time watched to backend
    function sendTimeWatched() {
        if (watchTimerData.current.secondsWatched > 0) {
            //send time watched to backend
            if (watchTimerClock.current % 180 === 0) {
                // console.log('Sending time watched to mixpanel', watchTimerData.current.secondsWatchedSinceLastReport)
                const secondsWatched = watchTimerData.current.secondsWatchedSinceLastReport;
                const secondsWaiting = watchTimerData.current.secondsWaitingSinceLastReport;
                watchTimerData.current.secondsWatchedSinceLastReport = 0;
                watchTimerData.current.secondsWaitingSinceLastReport = 0;
                mixpanel.track(
                        'Watched Video',
                        {
                            'Time Watched': Math.round(secondsWatched),
                            'Attachment ID': info.attachment_id,
                            'Owner ID': info.owner_id,
                            'Quality': playerRef?.current?.videoHeight(),
                            'Buffering Time': Math.round(secondsWaiting),
                            'Playback Rate': playerRef?.current?.playbackRate(),
                        }
                    )
            }

            //send time watched to backend
            if (watchTimerClock.current % 3 === 0 && playerRef?.current?.currentTime() > 0) {
                console.log('Sending time watched to backend')
                patchAttachmentView(info.attachment_id, {
                    // attachment_timestamp: Math.round(watchTimerData.current.secondsWatched),
                    attachment_timestamp: playerRef.current.currentTime(),
                });
            }
        }
    }

    // Initialize the Video.js player
    React.useEffect(() => {

        watchReporter.current = setInterval(() => {
            watchTimerClock.current = watchTimerClock.current + 1;

            if (watchTimerData.current.watching) {
                stopWatchTimer();
                // console.log('Time watched: ' + watchTimerData.current.secondsWatchedSinceLastReport)
                // console.log('Time waiting: ' + watchTimerData.current.secondsWaitingSinceLastReport)
                sendTimeWatched();
                startWatchTimer();
            } else {
                // console.log('Time watched: ' + watchTimerData.current.secondsWatchedSinceLastReport)
                // console.log('Time waiting: ' + watchTimerData.current.secondsWaitingSinceLastReport)
                sendTimeWatched();
            }
        }, 1000);

      // Make sure Video.js player is only initialized once
      if (!playerRef.current) {
        console.log('Initializing player')
        // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
        const videoElement = document.createElement("video-js");
  
        // videoElement.classList.add('vjs-big-play-centered');
        videoRef.current.appendChild(videoElement);
  
        const player = playerRef.current = videojs(videoElement, options, () => {
          onReady && onReady(player);
        });

        player.src(
            {
                src: src,
                type: 'application/vnd.apple.mpegurl',
            }
        );

        player.currentTime(info.initial_time);

        startWatchTimer();
        
        player.on('play', () => {
            setPlaySymbol('❚❚'); 
            playFcn.current = () => playerRef.current.pause();
            startWatchTimer();
            console.log('play');
        });
        player.on('pause', () => {
            setPlaySymbol('▶'); 
            playFcn.current = () => playerRef.current.play()
            stopWatchTimer();
            console.log('pause');
        });
        player.on('waiting', () => {
            console.log('waiting');
            startWaitingTimer();
        });
        player.on('playing', () => {
            console.log('playing');
            stopWaitingTimer();
        });

        videojs.registerComponent('QualitySelector', VjsBridgeComponent);
        player.controlBar.addChild('QualitySelector', {}, 19);

        //Update an existing player in the `else` block here
      } else {
        const player = playerRef.current;
        //player.autoplay(options.autoplay);
        //player.src(options.sources);
      }
      return () => {
          clearInterval(watchReporter.current);
      }
    }, [options, videoRef, src]);
  
    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
      const player = playerRef.current;
      return () => {
        if (player && !player.isDisposed()) {
          console.log('Disposing player')
          player.dispose();
          playerRef.current = null;

          //send time watched to backend
          sendTimeWatched();
        }
      };
    }, [playerRef, src]);

    //handle backward and forward skips
    const [forwardSkipStep, setForwardSkipStep] = React.useState(0);
    const [backwardSkipStep, setBackwardSkipStep] = React.useState(0);
    const forwardSkipStepRef = React.useRef(forwardSkipStep);
    const backwardSkipStepRef = React.useRef(backwardSkipStep);
    const timerf = React.useRef(null);
    const timerb = React.useRef(null);

    const skipTimes = [10, 30, 60 ];
    const decelTime = 1500;
    function decelerateForwardSkips() {
        if (timerf.current) {
            clearTimeout(timerf.current);
        }
        timerf.current = setTimeout(function() {
            if (forwardSkipStepRef.current === 0)
                return;
            else {
                forwardSkipStepRef.current = Math.max(0,forwardSkipStepRef.current - 1);
                setForwardSkipStep(forwardSkipStepRef.current)
                decelerateForwardSkips();
            }
        }, decelTime);
    }
    function decelerateBackwardSkips() {
        if (timerb.current) {
            clearTimeout(timerb.current);
        }
        timerb.current = setTimeout(function() {
            if (backwardSkipStepRef.current === 0)
                return;
            else {
                backwardSkipStepRef.current = Math.max(0,backwardSkipStepRef.current - 1);
                setBackwardSkipStep(backwardSkipStepRef.current)
                decelerateBackwardSkips();
            }
        }, decelTime);
    }

    const handleSkip = (direction) => {
        if (direction === 'forward') {
            playerRef.current.currentTime(
                playerRef.current.currentTime() + skipTimes[forwardSkipStep]
            );
            forwardSkipStepRef.current = Math.min(skipTimes.length-1, forwardSkipStepRef.current + 1);
            setForwardSkipStep(forwardSkipStepRef.current);
            decelerateForwardSkips();
        } else if (direction === 'backward') {
            playerRef.current.currentTime(
                playerRef.current.currentTime() - skipTimes[backwardSkipStep]
            );
            backwardSkipStepRef.current = Math.min(skipTimes.length-1, backwardSkipStepRef.current + 1)
            setBackwardSkipStep(backwardSkipStepRef.current);
            decelerateBackwardSkips();
        }
    }
  
    return (
      <div data-vjs-player>
        <div ref={videoRef} />
        {(windowSize.width > 899 && props?.overlayControls) &&
        <OverlayVideoControlsContainer>
                <OverlaySkipButton 
                    onClick={() => handleSkip('backward')}>
                    {'-' + skipTimes[backwardSkipStep]}
                </OverlaySkipButton>
                <OverlayPlayButton onClick={playFcn.current}>
                    {playSymbol}
                </OverlayPlayButton>
                <OverlaySkipButton onClick={() => handleSkip('forward')}>
                    {'+' + skipTimes[forwardSkipStep]}
                </OverlaySkipButton>
        </OverlayVideoControlsContainer>
        }
      </div>
    );
}

VideoPlayer.propTypes = {
    options: PropTypes.object,
    onReady: PropTypes.func
};

const OverlayVideoControlsContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6vmin;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s;

    &:hover {
        opacity: 1;
    }
`;

const OverlayPlayButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10vmin;
    height: 10vmin;
    border-radius: 10%;
    background-color: rgba(0,0,0,0.5);
    color: rgba(200,200,200,.5);
    font-size: 8vmin;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: rgba(200,200,200,.8);
    }
`;

const OverlaySkipButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10vmin;
    height: 10vmin;
    border-radius: 10%;
    background-color: rgba(0,0,0,0.5);
    color: rgba(200,200,200,.5);
    font-size: 5vmin;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: rgba(200,200,200,.8);
    }
`;
 
export default VideoPlayer;